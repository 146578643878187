import { type FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { YStack } from '@shinetools/sunshine-universal';

import SkeletonText from 'components/SkeletonText';

const PlanSwitchEstimateDetailsSkeleton: FC = () => (
  <YStack gap="$space.16">
    <SkeletonText skeletonHeight="space-18" width="240px" />
    <Flex justifyContent="space-between">
      <SkeletonText width="180px" />
      <SkeletonText width="100px" />
    </Flex>
    <Flex justifyContent="space-between">
      <SkeletonText width="180px" />
      <SkeletonText width="60px" />
    </Flex>
    <Flex justifyContent="space-between">
      <SkeletonText width="200px" />
      <SkeletonText width="80px" />
    </Flex>
  </YStack>
);

export default PlanSwitchEstimateDetailsSkeleton;
