import { type ComponentProps, type FC, useRef, useState } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  IntlProvider,
  type MessageDescriptor,
  useIntl,
} from 'react-intl';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Flex, SimpleGrid, VStack } from '@chakra-ui/react';
import { Icon, Typography } from '@shinetools/sunshine-universal';

import { PlanId, SubscriptionPlanFrequency } from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';
import { optionalFormatMessage } from 'common/i18n/optionalFormatMessage';
import { getPlanCardColor, getPlanCardDescription } from 'common/plans/card';
import { getTierYearlyPlan } from 'common/plans/getTierYearlyPlan';
import { getYearlySavings } from 'common/plans/pricing';
import { tableSections } from 'common/plans/table';
import Button from 'components/_core/Button';
import Loader from 'components/Loader';
import { PlanCard } from 'components/plan/PlanCard';
import { PlanPrice } from 'components/plan/PlanPrice';
import { PlanStickyCards } from 'components/plan/PlanStickyCards';
import { PlanTable } from 'components/plan/PlanTable';
import { formatDuration } from 'helpers/date';

import { SubscriptionManagementLayout } from '../../components/SubscriptionManagementLayout';
import { getTrialPeriod } from '../../lib/getTrialPeriod';
import * as subscriptionManagementRoutes from '../../routes';
import {
  DowngradeBlockerDialog,
  type DowngradeBlockerReason,
} from './components/DowngradeBlockerDialog';
import { getDowngradeBlockerReason2025 } from './libs/getDowngradeBlockerReason2025';
import { SubscriptionManagementPlansDocument } from './plans.gql';

export const Plans2025: FC = () => {
  const companyContext = useCompanyContext();
  const planCardsRef = useRef<HTMLDivElement>(null);

  const query = useQuery(SubscriptionManagementPlansDocument, {
    variables: {
      companyProfileId: companyContext.companyProfileId!,
      includePlans: true,
      planIds: Object.values(PlanId).filter((id) => id.includes('2025')),
    },
  });

  const [downgradeBlocker, setDowngradeBlocker] =
    useState<DowngradeBlockerReason | null>(null);

  const scheduledDowngrade = query.data?.viewer.company.subscriptionPlan
    ?.scheduledDowngrade as PlanId | null;
  const intl = useIntl();

  if (query.loading || !query.data) {
    return <Loader />;
  }

  const { data } = query;

  const monthlyPlans = data.plans.filter(
    (p) => p.billingFrequency === SubscriptionPlanFrequency.Monthly,
  );

  const trialPeriod = getTrialPeriod(data.viewer.company.subscriptionPlan);

  return (
    // We override the intl provider for this React subtree, to allow for the `subtitle` rich text element on the entirety of the screen (not only for specific locales).
    <IntlProvider
      {...intl}
      defaultRichTextElements={{
        ...intl.defaultRichTextElements,
        subtitle: (chunks) => (
          <Typography.Text size="small" tag="span" variant="secondary">
            {chunks}
          </Typography.Text>
        ),
      }}
    >
      <SubscriptionManagementLayout
        prevRoute={subscriptionManagementRoutes.root}
      >
        <DowngradeBlockerDialog
          downgradeBlockerReason={downgradeBlocker}
          onClose={() => setDowngradeBlocker(null)}
        />

        <Flex direction="column" gap="space-8" marginBottom="space-48">
          <Typography.Header size="large">
            <FormattedMessage id="subscription.plans.title" />
          </Typography.Header>

          {trialPeriod.isOngoing ? (
            <Flex align="center" gap="space-6">
              <Icon color="$grey.800" icon="sparkles" size="small" />

              <Typography.Text>
                <FormattedMessage
                  id="subscription.duration_before_end_of_trial_period"
                  values={{
                    duration: formatDuration(trialPeriod.duration, {
                      format: ['years', 'months', 'days'],
                    }),
                  }}
                />
              </Typography.Text>
            </Flex>
          ) : null}
        </Flex>

        <VStack align="stretch" spacing="space-48">
          <SimpleGrid
            columns={monthlyPlans.length}
            gap={{
              base: 'space-16',
              xl: 'space-16',
            }}
            ref={planCardsRef}
          >
            {monthlyPlans.map((monthlyPlan) => {
              const yearlyPlan = getTierYearlyPlan(monthlyPlan, data.plans);

              const isCurrentPlan =
                data.viewer.company.currentPlan.id === monthlyPlan.id;

              const isCurrentPlanSameTier =
                data.viewer.company.currentPlan.tier === monthlyPlan.tier;

              const isCurrentPlanMensual =
                data.viewer.company.currentPlan.billingFrequency ===
                monthlyPlan.billingFrequency;

              const isAlreadyScheduledDowngrade =
                scheduledDowngrade === monthlyPlan.id;

              /**
               * Conditions to show the yearly CTA:
               *
               * - The same tier yearly plan exists
               * - The user current plan is monthly or yearly but not the same tier
               */
              const showYearlyCta =
                yearlyPlan && (isCurrentPlanMensual || !isCurrentPlanSameTier);

              const monthlyCtaVariant = isCurrentPlanSameTier
                ? 'secondary'
                : 'primary';

              return (
                <PlanCard
                  color={getPlanCardColor(monthlyPlan.brandColor)}
                  description={intl.formatMessage(
                    getPlanCardDescription(monthlyPlan.tier),
                  )}
                  key={monthlyPlan.id}
                  title={monthlyPlan.brandName}
                >
                  <Flex flexDirection="column" gap="space-32">
                    <Flex flexDirection="column" gap="space-12">
                      <Typography.Text size="large">
                        <FormattedMessage
                          id="subscription.plan_card.price"
                          values={{
                            price: (
                              <Typography.HeaderNumber size="veryLarge">
                                <FormattedNumber
                                  currency="EUR"
                                  minimumFractionDigits={
                                    monthlyPlan.pricing.taxExcluded % 100
                                      ? 2
                                      : 0
                                  }
                                  style="currency"
                                  value={monthlyPlan.pricing.taxExcluded / 100}
                                />
                              </Typography.HeaderNumber>
                            ),
                          }}
                        />
                      </Typography.Text>

                      {showYearlyCta ? (
                        <Flex
                          alignItems="center"
                          as={Link}
                          gap="space-8"
                          to={generatePath(
                            subscriptionManagementRoutes.planDetails,
                            { planId: yearlyPlan.id },
                          )}
                        >
                          <Typography.Text
                            size="small"
                            textDecorationLine="underline"
                          >
                            <FormattedMessage
                              id="subscription.plan_card.choose_yearly_to_save"
                              values={{
                                yearlySavings:
                                  getYearlySavings(monthlyPlan, yearlyPlan)
                                    .taxExcluded / 100,
                              }}
                            />
                          </Typography.Text>

                          <Icon icon="arrow-right" size={12} />
                        </Flex>
                      ) : (
                        // Placeholder to keep the Button at the same height as the other PlanCards
                        <Box height="space-20" />
                      )}
                    </Flex>

                    <Flex flexDirection="column" gap="space-16">
                      <Button
                        {...(() => {
                          if (isCurrentPlan || isAlreadyScheduledDowngrade) {
                            return {
                              isDisabled: true,
                            };
                          }

                          const blockerReason = getDowngradeBlockerReason2025({
                            plans: data,
                            targetPlan: monthlyPlan,
                          });

                          if (blockerReason) {
                            return {
                              onClick: () => {
                                setDowngradeBlocker(blockerReason);
                              },
                            };
                          }

                          return {
                            as: Link,
                            to: generatePath(
                              subscriptionManagementRoutes.planDetails,
                              {
                                planId: monthlyPlan.id,
                              },
                            ),
                          };
                        })()}
                        textAlign="center"
                        variant={monthlyCtaVariant}
                      >
                        {(() => {
                          if (isCurrentPlan) {
                            return (
                              <FormattedMessage id="subscription.plan_card.current_plan_disabled_cta" />
                            );
                          }

                          if (isCurrentPlanSameTier && !isCurrentPlanMensual) {
                            return (
                              <FormattedMessage id="subscription.plan_card.switch_to_mensual_cta" />
                            );
                          }

                          if (isAlreadyScheduledDowngrade) {
                            return (
                              <FormattedMessage
                                id="subscription.plan_card.already_scheduled_downgrade_disabled_cta"
                                values={{
                                  nextBillingDate: (
                                    <FormattedDate
                                      day="numeric"
                                      month="short"
                                      value={
                                        new Date(
                                          data.viewer.company.subscriptionPlan!.billingPeriod.currentTermEndAt!,
                                        )
                                      }
                                      year="numeric"
                                    />
                                  ),
                                }}
                              />
                            );
                          }

                          return (
                            <FormattedMessage
                              id="subscription.plan_card.select_cta"
                              values={{
                                planName: monthlyPlan.brandName,
                              }}
                            />
                          );
                        })()}
                      </Button>
                    </Flex>
                  </Flex>
                </PlanCard>
              );
            })}
          </SimpleGrid>

          <PlanStickyCards
            boxSizing="content-box"
            items={monthlyPlans.map((plan) => (
              <PlanCard
                color={getPlanCardColor(plan.brandColor)}
                key={plan.id}
                title={plan.brandName}
                variant="small"
              >
                <PlanPrice
                  per="month"
                  price={plan.pricing.taxExcluded}
                  variant="small"
                />
              </PlanCard>
            ))}
            maxWidth={1256}
            paddingX={{ base: 40, lg: 60, xl: 92 }}
            planCardsRef={planCardsRef}
          />

          {tableSections.map((section) => {
            return (
              <PlanTable
                key={section.key}
                rows={section.rows.map(
                  ({
                    getCellData,
                    key,
                  }): ComponentProps<typeof PlanTable>['rows'][number] => {
                    return {
                      data: monthlyPlans.map((plan) => {
                        const { isChecked, text } = getCellData(plan, intl);

                        if (isChecked !== undefined) {
                          return (
                            <Icon
                              color={isChecked ? '$grey.800' : '$grey.500'}
                              icon={isChecked ? 'tick' : 'cross'}
                              margin="auto"
                              size="small"
                            />
                          );
                        }

                        return text;
                      }),
                      subtitle: optionalFormatMessage(intl, {
                        id: `onboarding.pricing_plan_2025.selection.row.${key}.subtitle`,
                      }),
                      tag: optionalFormatMessage(intl, {
                        id: `onboarding.pricing_plan_2025.selection.row.${key}.tag`,
                      }),
                      title: intl.formatMessage({
                        id: `onboarding.pricing_plan_2025.selection.row.${key}.title`,
                      } as MessageDescriptor),
                      tooltip: optionalFormatMessage(intl, {
                        id: `onboarding.pricing_plan_2025.selection.row.${key}.tooltip`,
                      }),
                    };
                  },
                )}
                title={intl.formatMessage({
                  id: `onboarding.pricing_plan_2025.selection.section.${section.key}.title`,
                } as MessageDescriptor)}
              />
            );
          })}
        </VStack>
      </SubscriptionManagementLayout>
    </IntlProvider>
  );
};
