import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip as ChakraTooltip,
  type TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';
import { isWebTouchable } from '@tamagui/core';

export interface TooltipProps extends ChakraTooltipProps {
  description: string | React.ReactNode;
  /**
   * Fallback to Popover component for non-hoverable screens.
   * To be used carefully as Popover doesn’t support Tooltip props (like isDisabled for instance)
   */
  enableMobileFallback?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  description,
  enableMobileFallback,
  ...props
}) => {
  const descriptionEl =
    typeof description === 'string' ? (
      <Typography.Text size="small" variant="white">
        {description}
      </Typography.Text>
    ) : (
      description
    );

  /**
   * Using Popover since Tooltip doesn’t trigger on mobile
   * @see https://github.com/chakra-ui/chakra-ui/issues/7107
   */
  if (isWebTouchable && enableMobileFallback) {
    return (
      <Popover>
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent bg="grey.800" border="none" maxWidth="320px">
          <PopoverArrow bg="grey.800" boxShadow="none" />
          <PopoverBody>{descriptionEl}</PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <ChakraTooltip
      bg="grey.800"
      borderRadius="radius-8"
      hasArrow
      label={descriptionEl}
      paddingX="space-8"
      paddingY="space-4"
      placement="top"
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;
