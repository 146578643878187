/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ERROR_CODES } from '@shinetools/errors';
import type * as H from 'history';
import { createModel } from 'xstate/lib/model';

import { baseContext } from 'features/Bento/libs/machines';
import baseEvents from 'features/Bento/libs/machines/config/baseEvents';
import { type FunctionReturn } from 'features/Bento/types/helpers';
import { type Device } from 'helpers/device';

import { type SignupReferral } from '../types/SignupReferral';
import { type Services } from './services';

export type CompanyProfileType =
  | 'solo'
  | 'fewer-than-ten-employees'
  | 'more-than-ten-employees';

type ErrorCodes = keyof typeof ERROR_CODES;

export type CompanyCreationType = 'society' | 'independent' | 'unknown';

export enum CompanyCreationLegalForm {
  SAS = 'sas',
  SASU = 'sasu',
  EURL = 'eurl',
  SARL = 'sarl',
  SCI = 'sci',
  UNKNOWN = 'IDK',
  CAP_DEP = 'capdep',
}

export enum Target {
  CompanyCreationSASU = 'company-creation-sasu',
}

/**
 * An affiliation context is set if at least parameter ae is defined
 * @param ae: the affiliate id
 * @param sourceUrl: the affiliation url the user comes from, extracted in query param affiliate
 * @param aecid: the click id of the user - optional, depends of the setup on Affilae platform for this affiliate
 * @param visitedAt: the timestamp of the visit, optional, as this parameters is newly implemented
 * some user may not have it in their cookies, will set it in the affiliation service
 */

export type AffiliationContext = {
  sourceUrl: string;
};

export type ReferralContext = {
  code: string;
};

/**
 * A placeholder function that should be replaced at machine instantiation.
 */
const getHistory: () => H.History<unknown> = () => {
  throw Error('Not implemented');
};

/**
 * Placeholder function to get device that should be replaced at machine instantiation.
 */
const getDevice: () => Device = () => {
  throw Error('Not implemented');
};

const context = {
  ...baseContext,

  affiliate: null as string | null,

  affiliationContext: undefined as AffiliationContext | undefined,

  companyLegalForm: null as CompanyCreationLegalForm | null,

  companyProfileType: null as CompanyProfileType | null,

  helpers: {
    getDevice,
    getHistory,
  },
  inviteeFirstname: null as string | null,
  referral: null as SignupReferral,
  /**
   * Optional parameter passed in the url by the marketing website
   * to bypass the initial flow selection and land directly on the first page
   * of the selected signup flow.
   */
  target: null as Target | null,
};

export type Context = typeof context;

const creators = {
  events: {
    ...baseEvents,
    'done.invoke.standard.account': (data: 'ABORT' | undefined) => ({
      data,
    }),
    'done.invoke.verifyReferral': (
      data: FunctionReturn<Services['verifyReferral']>,
    ) => ({
      data,
    }),
    'error.platform.checkInvitation': (data: { code: ErrorCodes }) => ({
      data,
    }),
    'error.platform.redeemInvitation': (data: { code: ErrorCodes }) => ({
      data,
    }),
    GO_BENEFICIARY: () => ({}),
    GO_CAP_DEP: () => ({}),
    GO_COMPANY_CREATION: () => ({}),
    GO_SHINE_START_MICRO: () => ({}),
    GO_STANDARD: () => ({}),
    SUBMIT_COMPANY_CREATION_LEGAL_FORM: (data: CompanyCreationLegalForm) => ({
      data,
    }),
    SUBMIT_COMPANY_CREATION_TYPE: (data: CompanyCreationType) => ({ data }),
    SUBMIT_COMPANY_PROFILE_TYPE: (data: CompanyProfileType) => ({ data }),
    SUBMIT_HAS_STATUTES: (data: boolean) => ({ data }),
  },
};

const model = createModel(context, creators);

export default model;

export type Model = typeof model;
