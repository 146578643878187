import { type FC, useState } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  IntlProvider,
  useIntl,
} from 'react-intl';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Box,
  Flex,
  Image,
  List,
  ListItem,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Icon, Typography } from '@shinetools/sunshine-universal';

import { type PlanId } from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import Loader from 'components/Loader';
import { SubscriptionManagementLayout } from 'features/SubscriptionManagement/components/SubscriptionManagementLayout';
import * as subscriptionManagementRoutes from 'features/SubscriptionManagement/routes';

import {
  DowngradeBlockerDialog,
  type DowngradeBlockerReason,
} from './components/DowngradeBlockerDialog';
import { getDowngradeBlockerReason } from './libs/getDowngradeBlockerReason';
import { PLANS_METADATA } from './libs/plansMetadata';
import { SubscriptionManagementPlansDocument } from './plans.gql';

export const Plans2023: FC = () => {
  const companyContext = useCompanyContext();

  const query = useQuery(SubscriptionManagementPlansDocument, {
    variables: {
      companyProfileId: companyContext.companyProfileId!,
      includePlans: false,
    },
  });

  const scheduledDowngrade = query.data?.viewer.company.subscriptionPlan
    ?.scheduledDowngrade as PlanId | null;
  const intl = useIntl();

  const [downgradeBlocker, setDowngradeBlocker] =
    useState<DowngradeBlockerReason | null>(null);

  if (query.loading || !query.data) {
    return <Loader />;
  }

  const { data } = query;

  const allPlans = [
    ...data.viewer.company.currentPlan.downgradablePlans,
    data.viewer.company.currentPlan,
    ...data.viewer.company.currentPlan.upgradablePlans,
  ];

  return (
    // We override the intl provider for this React subtree, to allow for the `subtitle` rich text element on the entirety of the screen (not only for specific locales).
    <IntlProvider
      {...intl}
      defaultRichTextElements={{
        ...intl.defaultRichTextElements,
        subtitle: (chunks) => (
          <Typography.Text size="small" tag="span" variant="secondary">
            {chunks}
          </Typography.Text>
        ),
      }}
    >
      <SubscriptionManagementLayout
        prevRoute={subscriptionManagementRoutes.root}
      >
        <DowngradeBlockerDialog
          downgradeBlockerReason={downgradeBlocker}
          onClose={() => setDowngradeBlocker(null)}
        />

        <VStack align="stretch" spacing="space-48">
          <SimpleGrid
            columns={4}
            gap={{
              base: 'space-24',
              xl: 'space-40',
            }}
          >
            {allPlans.map((plan) => {
              const planMetadata = PLANS_METADATA[plan.id];

              const isAlreadyScheduledDowngrade =
                scheduledDowngrade === plan.id;

              const isCurrentPlan =
                plan.id === data.viewer.company.currentPlan.id;

              const isDowngrade =
                data.viewer.company.currentPlan.downgradablePlans.some(
                  ({ id }) => id === plan.id,
                );

              return (
                <Box
                  border="1px solid"
                  borderColor="grey.300"
                  borderRadius="radius-16"
                  key={plan.id}
                  overflow="hidden"
                >
                  <Flex
                    backgroundColor="grey.200"
                    justifyContent="center"
                    overflow="hidden"
                  >
                    <Image
                      height={120}
                      src={planMetadata.card}
                      transform="auto"
                      translateY="22%"
                    />
                  </Flex>
                  <Flex flexDirection="column" padding="space-16">
                    <Text fontWeight="weight-500" size="lg" variant="primary">
                      <FormattedMessage
                        id="subscription.plan_card.title"
                        values={{
                          planName: plan.brandName,
                        }}
                      />
                    </Text>

                    <Typography.Text marginBottom="$space.16">
                      <FormattedMessage
                        id="subscription.plan_card.price"
                        values={{
                          price: (
                            <Typography.HeaderNumber>
                              <FormattedNumber
                                currency="EUR"
                                minimumFractionDigits={
                                  plan.pricing.taxExcluded % 100 ? 2 : 0
                                }
                                style="currency"
                                value={plan.pricing.taxExcluded / 100}
                              />
                            </Typography.HeaderNumber>
                          ),
                        }}
                      />
                    </Typography.Text>

                    <Button
                      {...(() => {
                        if (isCurrentPlan || isAlreadyScheduledDowngrade) {
                          return {
                            isDisabled: true,
                          };
                        }

                        const blockerReason = getDowngradeBlockerReason({
                          plans: data,
                          targetPlan: plan,
                        });

                        if (blockerReason) {
                          return {
                            onClick: () => {
                              setDowngradeBlocker(blockerReason);
                            },
                          };
                        }

                        return {
                          as: Link,
                          to: generatePath(
                            subscriptionManagementRoutes.planDetails,
                            {
                              planId: plan.id,
                            },
                          ),
                        };
                      })()}
                      textAlign="center"
                      variant={isDowngrade ? 'secondary' : 'primary'}
                    >
                      {(() => {
                        if (isCurrentPlan) {
                          return (
                            <FormattedMessage id="subscription.plan_card.current_plan_disabled_cta" />
                          );
                        }

                        if (isAlreadyScheduledDowngrade) {
                          return (
                            <FormattedMessage
                              id="subscription.plan_card.already_scheduled_downgrade_disabled_cta"
                              values={{
                                nextBillingDate: (
                                  <FormattedDate
                                    day="numeric"
                                    month="short"
                                    value={
                                      new Date(
                                        data.viewer.company.subscriptionPlan!.billingPeriod.currentTermEndAt!,
                                      )
                                    }
                                    year="numeric"
                                  />
                                ),
                              }}
                            />
                          );
                        }

                        return (
                          <FormattedMessage
                            id="subscription.plan_card.select_cta"
                            values={{
                              planName: plan.brandName,
                            }}
                          />
                        );
                      })()}
                    </Button>

                    <List paddingY="space-24" spacing="space-24">
                      {planMetadata.sellingPoints.map((SellingPoint, index) => (
                        <ListItem display="flex" gap="space-16" key={index}>
                          <Box boxSize={16} paddingY="space-4">
                            <Icon icon="tick" size="small" />
                          </Box>
                          <Typography.Text>
                            <SellingPoint {...plan} />
                          </Typography.Text>
                        </ListItem>
                      ))}
                    </List>
                  </Flex>
                </Box>
              );
            })}
          </SimpleGrid>

          <Button
            alignSelf="center"
            as="a"
            href="https://www.shine.fr/tarifs/"
            icon="link"
            target="_blank"
            variant="secondary"
          >
            <FormattedMessage id="subscription.plans.see_offer_details_cta" />
          </Button>
        </VStack>
      </SubscriptionManagementLayout>
    </IntlProvider>
  );
};
