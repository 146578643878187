import { type FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FormattedDate,
  FormattedMessage,
  IntlProvider,
  useIntl,
} from 'react-intl';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Callout,
  Icon,
  Typography,
  YStack,
} from '@shinetools/sunshine-universal';
import { z } from 'zod';

import {
  PlanId,
  type PricingPlan,
  SubscriptionPlanFrequency,
} from '__generated__/GQL';
import useCompanyContext from 'common/hooks/useCompanyContext';
import Button from 'components/_core/Button';
import { formatDuration } from 'helpers/date';

import SkeletonButton from '../../../../components/SkeletonButton';
import SkeletonText from '../../../../components/SkeletonText';
import useToast from '../../../../components/ToastProvider/useToast';
import { INTERCOM_LAUNCHER_PROPS } from '../../../Intercom';
import { SubscriptionManagementLayout } from '../../components/SubscriptionManagementLayout';
import { getTrialPeriod } from '../../lib/getTrialPeriod';
import * as routes from '../../routes';
import { SubscriptionManagementOverviewDocument } from '../Overview/graphql/overview.gql';
import { PLANS_METADATA } from '../Plans/libs/plansMetadata';
import InsuranceTermsForm from './components/InsuranceTermsForm';
import InsuranceTermsFormSkeleton from './components/InsuranceTermsForm/InsuranceTermsFormSkeleton';
import PlanFrequencySwitch, {
  PlanFrequencySwitchSkeleton,
} from './components/PlanFrequencySwitch';
import PlanRecap, { PlanRecapSkeleton } from './components/PlanRecap';
import PlanSwitchEstimateDetails, {
  PlanSwitchEstimateDetailsSkeleton,
} from './components/PlanSwitchEstimateDetails';
import { ChangePlanDocument } from './graphql/changePlan.gql';
import { SubscriptionManagementPlansDetailDocument } from './graphql/planDetails.gql';
import { PlanSwitchEstimateDocument } from './graphql/planSwitchEstimate.gql';
import { formatPlanSwitchIneligibilityReasons } from './helpers/formatPlanSwitchIneligibilityReasons';

export const PlanDetails: FC = () => {
  const companyContext = useCompanyContext();
  const params = useParams<{ planId: PlanId }>();
  const [selectedPricingPlanId, setSelectedPricingPlanId] = useState<PlanId>(
    params.planId,
  );
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation<{ fromOverviewPage?: boolean }>();
  const { showToast } = useToast();

  const requiresInsuranceTermsAgreement =
    !!PLANS_METADATA[params.planId]?.requiresInsuranceTermsAgreement;

  const form = useForm({
    defaultValues: {
      hasAgreedToInsuranceTerms: false,
    },
    resolver: zodResolver(
      z.object({
        hasAgreedToInsuranceTerms: requiresInsuranceTermsAgreement
          ? z.literal(true, {
              errorMap: () => ({
                message: intl.formatMessage({
                  id: 'subscription.plan_details.insurance_terms.error',
                }),
              }),
            })
          : z.boolean(),
      }),
    ),
  });

  const query = useQuery(SubscriptionManagementPlansDetailDocument, {
    onError() {
      history.replace(routes.plans);
    },
    variables: {
      companyProfileId: companyContext.companyProfileId!,
      planId: params.planId,
    },
  });

  const planSwitchEstimateQuery = useQuery(PlanSwitchEstimateDocument, {
    fetchPolicy: 'network-only',
    variables: {
      companyProfileId: companyContext.companyProfileId!,
      planId: selectedPricingPlanId,
    },
  });

  const [changePlan] = useMutation(ChangePlanDocument, {
    awaitRefetchQueries: true,
    onError(error) {
      showToast({
        message: error.message,
        type: 'error',
      });
    },
    refetchQueries: [
      {
        query: SubscriptionManagementOverviewDocument,
        variables: {
          companyProfileId: companyContext.companyProfileId!,
          includePlanChangeData: true,
          planIds: Object.values(PlanId),
        },
      },
    ],
  });

  if (query.loading || !query.data) {
    return (
      <SubscriptionManagementLayout
        asideContent={<PlanRecapSkeleton />}
        loading
        prevRoute={
          location.state?.fromOverviewPage ? routes.root : routes.plans
        }
      >
        <SkeletonText minWidth="120px" />
        <Box marginY="space-40">
          <YStack gap="$space.32">
            <PlanFrequencySwitchSkeleton />
            <Divider />
            <PlanSwitchEstimateDetailsSkeleton />
            <Divider />
            <InsuranceTermsFormSkeleton />
          </YStack>
        </Box>
        <SkeletonButton />
      </SubscriptionManagementLayout>
    );
  }

  const {
    viewer: { company },
  } = query.data;

  if (!company.subscriptionPlan) {
    throw new Error('No subscription plan found');
  }

  const ineligibleReasons = formatPlanSwitchIneligibilityReasons(
    planSwitchEstimateQuery?.data?.viewer?.company?.planSwitchEstimate
      ?.ineligibleReasons ?? [],
  );

  const trialPeriod = getTrialPeriod(company.subscriptionPlan);

  const targetPlanMetadata = PLANS_METADATA[selectedPricingPlanId];

  const pricingPlans = query.data.pricingPlansByTier.reduce(
    (acc, plan) => {
      if (plan.billingFrequency === 'MONTHLY') {
        acc.monthlyPlan = plan as PricingPlan;
      } else if (plan.billingFrequency === 'YEARLY') {
        acc.yearlyPlan = plan as PricingPlan;
      }
      return acc;
    },
    {} as { monthlyPlan?: PricingPlan; yearlyPlan?: PricingPlan },
  );

  const { monthlyPlan, yearlyPlan } = pricingPlans;

  const hasBothPlans = yearlyPlan && monthlyPlan;

  const targetPricingPlan = query.data.pricingPlansByTier.find(
    (plan) => plan.id === selectedPricingPlanId,
  );

  if (!targetPricingPlan) {
    throw new Error(
      `No pricing plan found for planId: ${selectedPricingPlanId}`,
    );
  }

  const showPlanSwitchEstimate =
    !ineligibleReasons.hasMoreThanOneMonthLeftInContractTerm &&
    targetPricingPlan.id !== company.subscriptionPlan.planId;

  return (
    <IntlProvider
      {...intl}
      defaultRichTextElements={{
        ...intl.defaultRichTextElements,
        subtitle: (chunks) => (
          <Typography.Text size="small" variant="secondary">
            {chunks}
          </Typography.Text>
        ),
      }}
    >
      <SubscriptionManagementLayout
        asideContent={<PlanRecap targetPricingPlan={targetPricingPlan} />}
        prevRoute={
          location.state?.fromOverviewPage ? routes.root : routes.plans
        }
      >
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(async () => {
              const previousPlan = company.currentPlan.id;

              const { errors } = await changePlan({
                variables: {
                  input: {
                    companyProfileId: companyContext.companyProfileId!,
                    planId: targetPricingPlan.id,
                  },
                },
              });

              if (!errors) {
                history.replace(
                  `${routes.root}?${new URLSearchParams({
                    'switched-from-plan': previousPlan,
                  })}`,
                );
              }
            })}
          >
            <Flex direction="column" gap="space-8">
              <Typography.Header size="large">
                <FormattedMessage id="subscription.plan_details.title" />
              </Typography.Header>

              {trialPeriod.isOngoing ? (
                <Flex align="center" gap="space-6">
                  <Icon color="$grey.800" icon="sparkles" size="small" />

                  <Typography.Text bold>
                    <FormattedMessage
                      id="subscription.duration_before_end_of_trial_period"
                      values={{
                        duration: formatDuration(trialPeriod.duration, {
                          format: ['years', 'months', 'days'],
                        }),
                      }}
                    />
                  </Typography.Text>
                </Flex>
              ) : null}
            </Flex>

            <Box marginY="space-40">
              <YStack gap="$space.32">
                {ineligibleReasons.hasMoreThanOneMonthLeftInContractTerm ? (
                  <Callout variant="information">
                    <Typography.Text>
                      <FormattedMessage
                        id="subscription.plan_details.one_more_month_left_in_contract_term_callout"
                        values={{
                          contractEndDate: (
                            <FormattedDate
                              day="numeric"
                              month="short"
                              value={
                                new Date(
                                  company.subscriptionPlan.billingPeriod.currentTermEndAt,
                                )
                              }
                              year="numeric"
                            />
                          ),
                          support_link: (content) => (
                            <Typography.Link {...INTERCOM_LAUNCHER_PROPS}>
                              {content}
                            </Typography.Link>
                          ),
                        }}
                      />
                    </Typography.Text>
                  </Callout>
                ) : null}
                {hasBothPlans ? (
                  <PlanFrequencySwitch
                    currentPricingPlanId={company.currentPlan.id}
                    monthlyPlan={monthlyPlan}
                    selectedPricingPlanId={selectedPricingPlanId}
                    setSelectedPricingPlanId={setSelectedPricingPlanId}
                    yearlyPlan={yearlyPlan}
                  />
                ) : null}
                {hasBothPlans && showPlanSwitchEstimate ? <Divider /> : null}
                <PlanSwitchEstimateDetails
                  currentBillingPeriodTermEnd={
                    company.subscriptionPlan.billingPeriod.currentTermEndAt
                  }
                  loading={planSwitchEstimateQuery.loading}
                  planSwitchEstimate={
                    planSwitchEstimateQuery?.data?.viewer?.company
                      ?.planSwitchEstimate
                  }
                  showPlanSwitchEstimate={showPlanSwitchEstimate}
                />

                {trialPeriod.isOngoing &&
                targetPricingPlan.billingFrequency ===
                  SubscriptionPlanFrequency.Yearly ? (
                  <Callout variant="warning">
                    <Typography.Text>
                      <FormattedMessage
                        id="subscription.plan_details.trial_period_end_callout"
                        values={{
                          duration: formatDuration(trialPeriod.duration, {
                            format: ['years', 'months', 'days'],
                          }),
                        }}
                      />
                    </Typography.Text>
                  </Callout>
                ) : null}
                {ineligibleReasons.hasInsufficientFunds ? (
                  <Callout variant="error">
                    <FormattedMessage
                      id="subscription.plan_details.insufficient_funds_callout"
                      values={{
                        planName: targetPricingPlan.brandName,
                      }}
                    />
                  </Callout>
                ) : null}
                {showPlanSwitchEstimate &&
                targetPlanMetadata.requiresInsuranceTermsAgreement ? (
                  <>
                    <Divider />
                    <InsuranceTermsForm
                      brandName={targetPricingPlan.brandName}
                    />
                  </>
                ) : null}
              </YStack>
            </Box>
            <Button
              alignSelf="flex-start"
              isDisabled={
                query.loading ||
                !query.data ||
                !planSwitchEstimateQuery.data ||
                planSwitchEstimateQuery.loading ||
                Object.values(ineligibleReasons).some(Boolean)
              }
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              <FormattedMessage
                id="subscription.plan_details.change_plan_cta"
                values={{
                  isSameTier: [monthlyPlan, yearlyPlan].some(
                    (plan) => plan?.id === company?.subscriptionPlan?.planId,
                  ),
                  planName: targetPricingPlan.brandName,
                }}
              />
            </Button>
          </form>
        </FormProvider>
      </SubscriptionManagementLayout>
    </IntlProvider>
  );
};
