import { type FC } from 'react';
import { Route, type RouteProps, Switch } from 'react-router-dom';

import { Invoices } from './pages/Invoices';
import { Overview } from './pages/Overview';
import { PlanDetails } from './pages/PlanDetails';
import { Plans2023 } from './pages/Plans/plans_2023';
import { Plans2025 } from './pages/Plans/plans_2025';
import * as routes from './routes';

export const WithinAppLayoutSubscriptionManagement: FC = () => {
  return (
    <Switch>
      <Route component={Overview} exact path={routes.root} />
      <Route component={Invoices} path={routes.invoices} />
    </Switch>
  );
};

export const fullPageSubscriptionManagementRoutes2023 = [
  {
    component: Plans2023,
    exact: true,
    path: routes.plans,
  },
  {
    component: PlanDetails,
    exact: true,
    path: routes.planDetails,
  },
] satisfies Array<RouteProps>;

export const fullPageSubscriptionManagementRoutes2025 = [
  {
    component: Plans2025,
    exact: true,
    path: routes.plans,
  },
  {
    component: PlanDetails,
    exact: true,
    path: routes.planDetails,
  },
] satisfies Array<RouteProps>;
