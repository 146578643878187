import { type FC } from 'react';
import {
  Skeleton as ChakraSkeleton,
  type SkeletonProps,
} from '@chakra-ui/react';

const SkeletonButton: FC<SkeletonProps> = (props) => (
  <ChakraSkeleton
    borderRadius="radius-4"
    endColor="grey.200"
    height="space-40"
    startColor="grey.200"
    width="space-128"
    {...props}
  />
);

export default SkeletonButton;
