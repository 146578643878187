import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, type BoxProps } from '@chakra-ui/react';

import Callout from 'components/_core/Callout';

import type * as GQLOverview from '../../graphql/overview.gql';
import { getInsuranceCoverage } from '../../helpers/getInsuranceCoverage';

export type OverviewInsuranceCoverageProps = BoxProps & {
  data?: GQLOverview.SubscriptionManagementOverviewQuery;
};

export const OverviewInsuranceCoverage: FC<OverviewInsuranceCoverageProps> = (
  props,
) => {
  const { data, ...rest } = props;

  const insurance = getInsuranceCoverage(data);

  if (insurance.isActive) {
    return null;
  }

  return (
    <Box {...rest}>
      <Callout hideIcon variant="error">
        <FormattedMessage
          id="subscription.overview.insurance_coverage.description"
          values={{
            currentPlan: insurance.plan,
          }}
        />
      </Callout>
    </Box>
  );
};
