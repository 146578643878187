import { type FC } from 'react';
import { XStack, YStack } from '@shinetools/sunshine-universal';

import SunshineCard from 'components/_core/SunshineCard';
import Skeleton from 'components/Skeleton';
import SkeletonText from 'components/SkeletonText';

const InsuranceTermsFormSkeleton: FC = () => (
  <YStack gap="$space.16">
    <YStack gap="$space.8">
      <SkeletonText skeletonHeight="space-18" width="240px" />
      <SkeletonText skeletonHeight="space-14" width="180px" />
    </YStack>

    <SunshineCard>
      <XStack gap="$space.8">
        <Skeleton borderRadius="radius-4" height="space-16" width="space-16" />
        <SkeletonText width="240px" />
      </XStack>
    </SunshineCard>
  </YStack>
);

export default InsuranceTermsFormSkeleton;
