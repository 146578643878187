import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    beneficiaries: {
      content: [
        `A beneficiary, or beneficial owner, is a legal entity or a physical person who exercises power over your company.`,
        `To be the beneficial owner of a company, the legal entity or the physical person:`,
      ],
      managerCondition: `Exercises managerial power over the company. For example: making decisions in general meetings of the company, appointing or dismissing members of the management team...`,
      or: 'or',
      sharesCondition: `Has at least 25% of the capital or voting rights`,
      title: `What is a beneficiary?`,
    },
    capDep: {
      content: `I did my capital deposit with Shine: very responsive, attentive, and surprisingly easy!`,
      personName: 'Nathan',
      personTitle: 'Founder of Care to be',
      title: `
      Your capital deposit,
      **100% online.**
      Simple, fast and secure.
      `,
    },
    companyProfile: {
      content:
        'Your company profile enables us to provide you with tailor-made services, offers and support.',
      title: 'Getting to know you better for better support',
    },
    guidanceHome: {
      title: 'more than 100,000 companies have adopted Shine',
    },
    joinAlvinVariant: {
      content: `The perfect pro account! A more than pleasant and intuitive interface, top features, a pleasant and available team. It's much more than a bank!`,
      personName: 'Alvin',
      personTitle: 'Founder of Mon Assistant',
      title: `
      Join
      **100,000**
      businesses
      and freelancers
      `,
    },
    joinNegeVariant: {
      content: `A easy-to-use and intuitive pro account. We were seduced by the security, all the features and the practical aspect of the app.`,
      personName: 'Anne-Sophie and Aliénor',
      personTitle: 'Founders of Nêge Paris',
      title: `
      Join
      **100,000**
      businesses
      and freelancers
      `,
    },
    passcode: {
      content: `This passcode will be used to log in to your Shine account from our website or via our mobile application.`,
      title: 'What is the purpose of this 4-digit passcode?',
    },
    personalInfo: {
      content: `This personal information allows us to:`,
      list: [
        'create a secure personal access for you;',
        'comply with the banking regulations imposed on us.',
      ],
      title: 'Why are you asking for this information?',
    },
    phone: {
      content: [
        `Your phone number is a quick way to connect and is easy to memorise.`,
        `It also allows us to offer you a more secure connection, thanks to SMS verification.`,
      ],
      title: 'Why use the phone number to login?',
    },
    referral: {
      content: [
        `You can contact the person who referred you to send you their referral link.`,
        `To do so, they need to go to their mobile app, in the PLUS tab and click on Recommend Shine.`,
      ],
      title: `I can't find my referral link`,
    },
    shineStartAdvertising: {
      title: 'Shine Micro, an offer for business creation',
    },
    shineStartLandbot: {
      content: [
        `Do you need more information to choose the most suitable legal form for your business?`,
        `We will ask you a series of questions to understand your requirements better and to enable you in setting up the most appropriate structure for your situation.`,
      ],
      title: `We are here to help you!`,
    },
    shineStartMicro: {
      content: `Formerly called "auto-entreprise", this legal form simplifies administrative procedures, does not require an annual balance sheet and allows exemption from VAT below a certain turnover.`,
      title: `More information on micro-enterprise`,
    },
    shineStartSociety: {
      content: `SAS (a simplified joint stock company), SASU (simplified joint stock company with sole shareholder), SARL (limited liability company), EURL (limited liability company with sole shareholder), SCI (property management company), etc... There are many legal forms. Each one has its advantages: the choice of the structure depends on the various parameters of your project and your requirements!`,
      title: `More information on the legal forms`,
    },
    statutesDefinition: {
      content:
        'This document details, among other things, the future activity of your company, the distribution of capital and the main rules governing the operation and management.',
      title: 'What is draft statutes?',
    },
    statutesHowTo: {
      content: [
        `Drafting the statutes is a key task in the process of setting up your company. It often requires the help of an expert to meet your expectations without omitting important elements.`,
        `Don't worry, we're here to help you!`,
      ],
      title: 'How are statutes drafted?',
    },
    storesLabel: 'Available on **Android** & **iOS**',
  },
  fr: {
    beneficiaries: {
      content: [
        `Un bénéficiaire, ou bénéficiaire effectif, est une personne physique ou morale qui exerce un pouvoir sur votre entreprise. `,
        `Pour être bénéficiaire effectif d’une entreprise, la personne physique ou morale :`,
      ],
      managerCondition: `Exerce un pouvoir de dirigeant·e sur l'entreprise. Par exemple : prendre des décisions dans les assemblées générales de l'entreprise, nommer ou révoquer des membres de l'équipe de direction...`,
      or: 'ou',
      sharesCondition: `Possède au moins 25 % du capital ou des droits de vote`,
      title: `Qu’est-ce qu’un bénéficiaire\u00a0?`,
    },
    capDep: {
      content: `J'ai fait mon dépôt de capital avec Shine : très réactif, à l’écoute, et d’une facilité surprenante\u00a0!`,
      personName: 'Nathan',
      personTitle: 'Fondateur de Care to be',
      title: `
      Le dépôt de capital
      **100 % en ligne.**
      C’est simple,
      rapide et sécurisé.
      `,
    },
    companyProfile: {
      content: `Votre profil d’entreprise nous permet de vous proposer les services et offres adaptées, ainsi qu’un accompagnement sur-mesure.`,
      title: 'Mieux vous connaître pour mieux vous accompagner',
    },
    guidanceHome: {
      title: '+ de 100 000 entreprises ont adopté Shine',
    },
    joinAlvinVariant: {
      content: `Le compte pro parfait\u00a0! Une interface plus qu'agréable et intuitive, des fonctionnalités au top, une équipe agréable et disponible. C'est bien plus qu'une banque\u00a0!`,
      personName: 'Alvin',
      personTitle: 'Fondateur de Mon Assistant',
      title: `
      Rejoignez
      **100 000**
      entreprises
      et indépendant·es
      `,
    },
    joinNegeVariant: {
      content: `Un compte pro très simple d'utilisation et intuitif. On a été séduites par la sécurité de Shine, par toutes les fonctionnalités et la praticité de l'application.`,
      personName: 'Anne-Sophie et Aliénor',
      personTitle: 'Fondatrices de Nêge Paris',
      title: `
      Rejoignez
      **100 000**
      entreprises
      et indépendant·es
      `,
    },
    passcode: {
      content: `Ce code secret vous servira pour vous connecter à votre compte depuis notre site internet ou via notre application mobile.`,
      title: 'À quoi sert ce code personnel à 4 chiffres\u00a0?',
    },
    personalInfo: {
      content: `Ces informations personnelles nous permettent :`,
      list: [
        'de vous créer un accès personnel sécurisé ;',
        'd’être en conformité avec les règles bancaires qui nous sont imposées.',
      ],
      title: 'Pourquoi demandez-vous ces informations\u00a0?',
    },
    phone: {
      content: [
        `Votre numéro de téléphone est un moyen de connexion rapide et facilement mémorisable.`,
        `Il nous permet aussi de vous proposer une connexion plus sécurisée, grâce aux SMS de vérification.`,
      ],
      title:
        'Pourquoi utiliser le numéro de téléphone comme identifiant\u00a0?',
    },
    referral: {
      content: [
        `Vous pouvez contacter la personne qui vous parraine pour qu’elle vous renvoie son lien de parrainage.`,
        `Pour ce faire, elle doit se rendre sur son application mobile, dans l’onglet **PLUS > recommander Shine**.`,
      ],
      title: 'Je ne retrouve pas mon lien de parrainage',
    },
    shineStartAdvertising: {
      title: 'Shine Micro, une offre pour la création d’entreprise',
    },
    shineStartLandbot: {
      content: [
        `Vous avez besoin de plus d’informations pour choisir la forme juridique la plus adaptée à votre activité\u00a0?`,
        `Nous allons vous poser une série de questions pour mieux cerner votre besoin et vous permettre de créer la structure la plus adaptée à votre situation.`,
      ],
      title: `Nous sommes là pour vous aider\u00a0!`,
    },
    shineStartMicro: {
      content: `Anciennement auto-entreprise, ce statut simplifie les démarches administratives, n’exige pas de bilan comptable annuel et permet l’exonération de TVA sous un certain seuil de chiffre d’affaires.`,
      title: `Plus d’informations sur la micro-entreprise`,
    },
    shineStartSociety: {
      content: `SAS, SASU, SARL, EURL, SCI… Il existe de nombreuses formes juridiques. Chacune comporte des avantages\u00a0: le choix du statut dépend des différents paramètres de votre projet et de vos besoins\u00a0!`,
      title: `Plus d’informations sur les sociétés`,
    },
    statutesDefinition: {
      content:
        'Ce document détaille entre autre la future activité de votre entreprise, la répartition du capital et les principales règles de fonctionnement et de gestion.',
      title: 'Qu’est-ce qu’un projet de statuts\u00a0?',
    },
    statutesHowTo: {
      content: [
        `Rédiger des statuts est une tâche clé dans le processus de création de votre entreprise. Elle requiert souvent l’aide d’un·e expert·e pour correspondre à vos attentes sans omettre d’éléments importants.`,
        `Pas d’inquiétude, nous sommes là pour vous aider !`,
      ],
      title: 'Comment rédiger des statuts\u00a0?',
    },
    storesLabel: 'Disponible sur **Android** & **iOS**',
  },
} as const);

export default locales;
