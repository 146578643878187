import { type IntlShape } from 'react-intl';

import { type OnboardingPlan, type PricingPlan } from '__generated__/GQL';
import {
  type PricingPlanFeesFragment,
  type PricingPlanLimitsFragment,
} from 'common/graphql/fragments/pricingPlan.gql';

export type PricingTableSectionItem = {
  key: PricingTableSection;
  rows: PricingTableRowItem[];
};

/**
 * A common type that works for both OnboardingPlan & PricingPlan graphql types
 * Ideally we should create a graphql interface for these types and use it
 */
export type PricingTablePlan = Pick<
  OnboardingPlan,
  'customerSupport' | 'tier'
> &
  Pick<PricingPlan, 'customerSupport' | 'tier'> & {
    fees: PricingPlanFeesFragment;
    limits: PricingPlanLimitsFragment;
    // the types below differ between OnboardingPlan & PricingPlan so we need to type them with the loosest one
    paymentCard: Omit<OnboardingPlan['paymentCard'], '__typename'>;
    features: OnboardingPlan['features'];
  };

export type PricingTableRowItem = {
  key: PricingTableRow;
  getCellData: (
    plan: PricingTablePlan,
    intl: IntlShape,
  ) => PricingTableCellItem;
};

export type PricingTableCellItem =
  | { text: string; isChecked?: boolean }
  | { text?: string; isChecked: boolean };

export enum PricingTableSection {
  AccountAndCards = 'account_and_cards',
  Invoicing = 'invoicing',
  TrackingAndPreAccounting = 'tracking_and_pre_accounting',
  TeamExpenseManagement = 'team_expense_management',
  InsuranceAndSupport = 'insurance_and_support',
}

export enum PricingTableRow {
  // AccountAndCards
  IbanAccounts = 'iban_accounts',
  PhysicalCards = 'physical_cards',
  VirtualCards = 'virtual_cards',
  SepaTransfers = 'sepa_transfers',
  InstantTransfers = 'instant_transfers',
  IncomingInternationalTransfers = 'incoming_international_transfers',
  OutgoingInternationalTransfers = 'outgoing_international_transfers',
  BulkTransfers = 'bulk_transfers',
  CardPaymentAbroad = 'card_payment_abroad',
  CardPaymentLimit = 'card_payment_limit',
  CashWithdrawals = 'cash_withdrawals',
  WithdrawalLimit = 'withdrawal_limit',
  CheckDeposit = 'check_deposit',
  CashDeposit = 'cash_deposit',

  // Invoicing
  InvoicingTool = 'invoicing_tool',
  CustomisedQuotesAndInvoices = 'customised_quotes_and_invoices',
  ProductAndServicesCatalogue = 'product_and_services_catalogue',
  AutoReminder = 'auto_reminder',
  InvoiceCardPayment = 'invoice_card_payment',
  PaymentTerminal = 'payment_terminal',

  // TrackingAndPreAccounting
  CashflowManagementDashboard = 'cashflow_management_dashboard',
  UrssafEstimator = 'urssaf_estimator',
  AccountantAccess = 'accountant_access',
  AccountantExports = 'accountant_exports',
  Receipts = 'receipts',
  AccountingSoftwareIntegration = 'accounting_software_integration',

  // TeamExpenseManagement
  EmployeeAccess = 'employee_access',
  ExpenseAccountManagement = 'expense_account_management',
  EmployeeCards = 'employee_cards',
  EmployeeTransfers = 'employee_transfers',

  // InsuranceAndSupport
  OnlineSupport = 'online_support',
  PhoneSupport = 'phone_support',
  LegalAssistance = 'legal_assistance',
  DebtCollectionAssistance = 'debt_collection_assistance',
  AccidentInsurance = 'accident_insurance',
  MobileInsurance = 'mobile_insurance',
  LaptopInsurance = 'laptop_insurance',
  PurchaseProtection = 'purchase_protection',
  TransportDelays = 'transport_delays',
  HealthcareAbroad = 'healthcare_abroad',
  RentalCar = 'rental_car',
  FraudInsurance = 'fraud_insurance',
  PhishingProtection = 'phishing_protection',
  ContractLibrary = 'contract_library',
  Toolbox = 'toolbox',
  PartnerNetwork = 'partner_network',
}
