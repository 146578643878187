import { type FC, type ReactNode } from 'react';
import { Td, Th, Tr } from '@chakra-ui/react';
import { useToken } from '@chakra-ui/system';
import {
  Tag,
  Typography,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';

import SunshineIcon from 'components/_core/SunshineIcon';
import Tooltip from 'components/_core/Tooltip';

type RowProps = {
  title: string;
  subtitle?: string;
  tooltip?: string;
  tag?: string;
  data: (string | ReactNode)[];
};

export const Row: FC<RowProps> = ({ data, subtitle, tag, title, tooltip }) => {
  const [grey300] = useToken('colors', ['grey.300']);

  return (
    <Tr
      _first={{ borderTopColor: 'transparent' }}
      borderY={`solid 1px ${grey300}`}
      paddingY="space-32"
    >
      <Th paddingY="space-32" textAlign="left">
        <YStack gap="$space.8">
          <XStack alignItems="center" gap="$space.8">
            <Typography.Text variant="secondary">{title}</Typography.Text>
            {tooltip ? (
              <Tooltip description={tooltip} enableMobileFallback>
                <SunshineIcon color="grey.600" name="information" />
              </Tooltip>
            ) : null}
          </XStack>
          {subtitle ? (
            <Typography.Text variant="discreet">{subtitle}</Typography.Text>
          ) : null}

          {tag ? <Tag colorScheme="blue">{tag}</Tag> : null}
        </YStack>
      </Th>

      {data.map((item, index) => (
        <Td key={index} paddingY="space-32" textAlign="center">
          <Typography.Text>{item}</Typography.Text>
        </Td>
      ))}
    </Tr>
  );
};
