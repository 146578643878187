import { companyContextStorage } from 'common/hooks/useCompanyContext/companyContextStorage';
import { type Gender } from 'common/types';
import { OBError } from 'features/Bento/libs/errors/OBError';
import { OBErrorCode } from 'features/Bento/libs/errors/OBErrorCode';
import { type ExtractEventFrom } from 'features/Bento/types/Machine';
import { decodeToken, saveUser } from 'helpers/auth';
import { checkVerificationCode, startRegister } from 'helpers/auth/service';
import { type User } from 'helpers/auth/user.schema';

import { enhancedEndRegister } from '../auth';
import { type AccountType, type Context, type Model } from './model';

const services = {
  checkVerificationCode: async (
    context: Context,
    event: ExtractEventFrom<Model, 'SUBMIT_VERIFICATION_CODE'>,
  ) => {
    if (
      event.type !== 'SUBMIT_VERIFICATION_CODE' ||
      typeof event.data !== 'string'
    ) {
      throw new Error(`Invalid event ${event.type} on checkVerificationCode`);
    }

    if (!context.phoneNumber) {
      throw new Error(`No phoneNumber in checkVerificationCode`);
    }

    const res = await checkVerificationCode({
      phoneNumber: context.phoneNumber,
      verificationCode: event.data,
    }).catch((error) => {
      /**
       * The verificationCode submitted was invalid (or expired, but we can't differentiate)
       */
      if (error?.status === 400) {
        throw new OBError(OBErrorCode.CODE_NOT_MATCH);
      }

      throw error;
    });

    return res.data;
  },

  createAccountAndLogin: async (context: Context) => {
    const {
      accountType,
      affiliationContext,
      companyProfileType,
      form: { email, firstName, gender, hasAgreedToTermsOfService, lastName },
      helpers: { getDevice },
      passcode,
      phoneNumber,
      phoneNumberOwnershipToken,
      referral,
    } = context;

    if (!phoneNumber || !passcode || !phoneNumberOwnershipToken) {
      throw Error(
        'phoneNumber, passcode and phoneNumberOwnershipToken should be defined',
      );
    }

    // In case it was not properly cleaned on logout, e.g: session expiration
    companyContextStorage.clear();

    const device = getDevice();
    const tokens = await enhancedEndRegister({
      affiliationContext,
      device,

      email,

      firstName,

      /** @todo fix typings */
      gender: gender as Gender,

      hasAgreedToTermsOfService,
      lastName,
      passcode,
      phoneNumber,
      phoneNumberOwnershipToken,
      referralContext: referral ? { code: referral.code } : undefined,
      signupAccountType: accountType as AccountType,
      signupCompanyProfileType: companyProfileType,
    });

    const decoded = decodeToken(tokens.access_token);

    if (!decoded) {
      throw Error('Cannot decode access token');
    }

    const uid = decoded.sub;
    const user: User = {
      firebaseToken: tokens.firebaseToken,
      phone: phoneNumber,
      token: tokens.access_token,
      uid,
      userHash: tokens.user_hash,
    };

    saveUser(user);
  },

  startRegister: async (context: Context) => {
    const { phoneNumber } = context;

    if (!phoneNumber) {
      throw Error('phone should be defined');
    }

    try {
      await startRegister({ phone: phoneNumber });
    } catch (error) {
      throw Error(OBErrorCode.NETWORK);
    }
  },
};

export default services;

export type Services = typeof services;
