import { type PlanId } from '__generated__/GQL';

import {
  type PlanChangeSuccessDialogFragment,
  type PlanChangeSuccessDialogPlansFragment,
} from '../../graphql/overview.gql';

/**
 * Returns metadata about a plan change
 */
export const getPlanChangeData = (
  switchedFromPlan: PlanId,
  { currentPlan, subscriptionPlan }: PlanChangeSuccessDialogFragment,
  plans: PlanChangeSuccessDialogPlansFragment[],
) => {
  if (!subscriptionPlan) {
    throw new Error('No subscription plan found');
  }

  const getPlan = (planId: PlanId) => {
    const plan = plans.find((p) => p.id === planId);

    if (!plan) {
      throw new Error(`Plan ${planId} not found`);
    }

    return plan;
  };

  /**
   * Handles a downgrade.
   * The only way to know the target plan is to check the `scheduledDowngrade` field (all other endpoints continue to return the pre-downgrade plan).
   */
  if (subscriptionPlan.scheduledDowngrade) {
    return {
      isDowngrade: true,
      nextPlan: getPlan(subscriptionPlan.scheduledDowngrade as PlanId),
      previousPlan: getPlan(currentPlan.id),
    };
  }

  /**
   * Handles an upgrade.
   */
  const nextPlan = getPlan(currentPlan.id);
  const previousPlan = getPlan(switchedFromPlan);

  return {
    hasUnlockedNewPhysicalCards:
      nextPlan.paymentCard.type !== previousPlan.paymentCard.type ||
      (previousPlan.limits.physicalPaymentCard.value === 0 &&
        nextPlan.limits.physicalPaymentCard.value > 0),
    isDowngrade: false,
    nextPlan,
    previousPlan,
  };
};
