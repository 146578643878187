import { type FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Box, Flex } from '@chakra-ui/react';
import { Typography, YStack } from '@shinetools/sunshine-universal';

import { type PlanSwitchEstimate, PlanSwitchSchedule } from '__generated__/GQL';

import { PlanSwitchEstimateDetailsSkeleton } from './index';

type PlanSwitchEstimateDetailsProps = {
  currentBillingPeriodTermEnd: string;
  loading: boolean;
  planSwitchEstimate?: Pick<
    PlanSwitchEstimate,
    'setupFees' | 'planSwitchSchedule'
  >;
  showPlanSwitchEstimate: boolean;
};

const PlanSwitchEstimateDetails: FC<PlanSwitchEstimateDetailsProps> = ({
  currentBillingPeriodTermEnd,
  loading,
  planSwitchEstimate,
  showPlanSwitchEstimate,
}) => {
  if (!showPlanSwitchEstimate) {
    return null;
  }

  if (loading || !planSwitchEstimate) {
    return <PlanSwitchEstimateDetailsSkeleton />;
  }

  return (
    <YStack gap="$space.16">
      <Typography.Text bold size="large">
        <FormattedMessage id="subscription.plan_details.payment_method.title" />
      </Typography.Text>

      <Flex justifyContent="space-between">
        <Typography.Text>
          <FormattedMessage id="subscription.plan_details.payment_method.label" />
        </Typography.Text>
        <Typography.Text bold>
          <FormattedMessage id="subscription.plan_details.payment_method.main_account" />
        </Typography.Text>
      </Flex>
      <Box>
        <Flex justifyContent="space-between">
          <Typography.Text>
            <FormattedMessage id="subscription.plan_details.amount_due.label" />
          </Typography.Text>
          <Typography.Text bold>
            <FormattedMessage
              id="subscription.plan_details.amount_due.value"
              values={{
                immediateInvoicedAmount:
                  planSwitchEstimate.setupFees.taxIncluded / 100,
              }}
            />
          </Typography.Text>
        </Flex>
        {planSwitchEstimate.setupFees.taxIncluded > 0 ? (
          <Typography.Text size="small" variant="secondary">
            <FormattedMessage id="subscription.plan_details.amount_due.adjustment_callout" />
          </Typography.Text>
        ) : null}
      </Box>
      <Flex justifyContent="space-between">
        <Typography.Text>
          <FormattedMessage id="subscription.plan_details.plan_start.label" />
        </Typography.Text>
        <Typography.Text bold>
          {planSwitchEstimate.planSwitchSchedule ===
          PlanSwitchSchedule.Immediate ? (
            <FormattedMessage id="subscription.plan_details.plan_start.value.immediate" />
          ) : (
            <FormattedMessage
              id="subscription.plan_details.plan_start.value.end_of_period"
              values={{
                newPlanStartPayingDate: (
                  <FormattedDate
                    day="numeric"
                    month="short"
                    value={new Date(currentBillingPeriodTermEnd)}
                    year="numeric"
                  />
                ),
              }}
            />
          )}
        </Typography.Text>
      </Flex>
    </YStack>
  );
};

export default PlanSwitchEstimateDetails;
