import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { type PlanId } from '__generated__/GQL';
import Button from 'components/_core/Button';
import SkeletonButton from 'components/SkeletonButton';

import * as subscriptionManagementRoutes from '../../../../../routes';
import { PricingPlansByTierDocument } from '../../../graphql/pricingPlansByTier.gql';

interface ModifyPlanButtonProps {
  planId?: PlanId;
}

export const ModifyPlanButton: FC<ModifyPlanButtonProps> = ({ planId }) => {
  const pricingPlansByTierQuery = useQuery(PricingPlansByTierDocument, {
    skip: !planId,
    variables: planId ? { planId } : undefined,
  });

  if (!pricingPlansByTierQuery.data || pricingPlansByTierQuery.loading) {
    return <SkeletonButton />;
  }

  if (pricingPlansByTierQuery.data.pricingPlansByTier.length < 2) {
    return null;
  }

  return (
    <Button
      as={Link}
      textAlign="center"
      to={{
        pathname: generatePath(subscriptionManagementRoutes.planDetails, {
          planId,
        }),
        state: {
          fromOverviewPage: true,
        },
      }}
      variant="inline-primary"
    >
      <FormattedMessage id="subscription.overview.header.billing.cta" />
    </Button>
  );
};
