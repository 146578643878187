import { type PricingPlan } from '__generated__/GQL';

type GenericPricingPlan = Pick<PricingPlan, 'tier' | 'id'>;

export const getTierYearlyPlan = <T extends GenericPricingPlan>(
  monthlyPlan: T,
  plans: T[],
) => {
  const yearlyPlans = plans.filter(
    (p) => p.tier === monthlyPlan.tier && p.id !== monthlyPlan.id,
  );

  // Security check to avoid showing multiple yearly plans
  if (yearlyPlans.length > 1) {
    return null;
  }

  return yearlyPlans[0];
};
