import { PlanTier, SubscriptionPlanFrequency } from '__generated__/GQL';

import * as routes from '../../../routes';
import { type SubscriptionManagementPlansDetailFragment } from '../../../subscriptionManagementPlansDetail.gql';
import { type DowngradeBlockerReason } from '../components/DowngradeBlockerDialog';
import type * as GQLPlans from '../plans.gql';
import { PLANS_METADATA } from './plansMetadata';

export const getDowngradeBlockerReason2025 = ({
  plans,
  targetPlan,
}: {
  plans: GQLPlans.SubscriptionManagementPlansQuery;
  targetPlan: SubscriptionManagementPlansDetailFragment;
}): DowngradeBlockerReason | null => {
  const walletsCount =
    plans.viewer.company.subscriptionPlan!.quotas.activeBankAccountsQuota.used;

  const { isLateOnPayment } = plans.viewer.company.subscriptionPlan!;
  const { currentPlan } = plans.viewer.company;

  if (isLateOnPayment) {
    return {
      targetPlanName: targetPlan.brandName,
      type: 'late-on-payment',
    };
  }

  const newWalletLimit = targetPlan.limits.activeBankAccounts.isUnlimited
    ? Infinity
    : targetPlan.limits.activeBankAccounts.value;

  if (walletsCount > newWalletLimit) {
    /**
     * Substracting 1 for the main bank account, which we do not display as a "wallet" allowance for the user.
     */
    return {
      currentWalletCount: walletsCount - 1,
      newLimit: newWalletLimit - 1,
      targetPlanName: targetPlan.brandName,
      type: 'bank-account-limit',
    };
  }

  /**
   * When the user is on an annual plan the Free Plan is not available in the downgradablePlans list.
   *
   * So we add a special case to allow downgrading from an annual plan to the Free Plan.
   */
  const isDowngrade =
    (currentPlan.billingFrequency === SubscriptionPlanFrequency.Yearly &&
      targetPlan.tier === PlanTier.Free) ||
    currentPlan.downgradablePlans.some(({ tier }) => tier === targetPlan.tier);

  if (isDowngrade) {
    const lostFeatures =
      PLANS_METADATA[plans.viewer.company.currentPlan.id]
        .lostFeaturesOnDowngrade[targetPlan.id];

    if (lostFeatures) {
      return {
        continueRoute: `${routes.plans}/${targetPlan.id}`,
        lostFeatures: lostFeatures,
        targetPlanName: targetPlan.brandName,
        type: 'discourager',
      };
    }
  }

  return null;
};
