import { type FC } from 'react';
import { FormattedMessage, type MessageDescriptor, useIntl } from 'react-intl';
import { Flex, Image, Spacer } from '@chakra-ui/react';
import {
  CustomerSupportType,
  Feature,
  isFeatureAvailable,
} from '@shinetools/pricing-plan-library';

import { PaymentCardType, PlanId } from '__generated__/GQL';
import { formatAmount } from 'common/formatAmount';
import Button from 'components/_core/Button';
import SunshineCard from 'components/_core/SunshineCard';
import Text from 'components/_core/Text';
import Price from 'components/Price';
import { type SupportedFeaturePreview } from 'features/Upgrade/types';
import { toMajorUnits } from 'helpers/amount';

import { type PlanChoicePageQuery } from '../../pages/PlanChoice/hooks/usePlanChoice/graphql/planChoicePage.gql';
import PlanDetailsBulletPoint from '../PlanDetailsBulletPoint';
import { getCardImage } from './helpers';
import {
  catchPhraseMessages,
  insurancesAssistanceDescriptionMessages,
  messages,
} from './messages';
import * as S from './styles';

const SEVEN_DAYS_BASIC_PAYMENT_CARD_LIMIT = 10000;
const SEVEN_DAYS_PREMIUM_PAYMENT_CARD_LIMIT = 15000;
const THIRTY_DAYS_BASIC_PAYMENT_CARD_LIMIT = 40000;
const THIRTY_DAYS_PREMIUM_PAYMENT_CARD_LIMIT = 60000;

interface PlanDetailsProps {
  className?: string;
  featurePreview?: SupportedFeaturePreview;
  isCurrentPlan?: boolean;
  plan: PlanChoicePageQuery['viewer']['company']['currentPlan']['upgradablePlans'][number];
  from?: string;
}

const PlanDetails: FC<PlanDetailsProps> = ({
  className,
  featurePreview,
  from,
  isCurrentPlan,
  plan,
}) => {
  const intl = useIntl();

  const catchPhrase =
    plan.id in catchPhraseMessages
      ? (catchPhraseMessages as Record<PlanId, MessageDescriptor>)[plan.id]
      : '';

  const hasAccessToBusinessCard =
    plan.paymentCard.type === PaymentCardType.BusinessWorldDebitCard;
  const nbOfPhysicalCards = plan.limits.physicalPaymentCard.value;
  const canHaveMultiplePhysicalCards = nbOfPhysicalCards > 1;

  const physicalCardImage = getCardImage({
    hasAccessToBusinessCard,
    nbOfPhysicalCards,
  });

  const hasUnlimitedVirtualCards =
    plan.limits.virtualPaymentCardMonth.isUnlimited;
  const nbOfVirtualCards = plan.limits.virtualPaymentCardMonth.value;
  const hasAccessToVirtualCards = nbOfVirtualCards > 0;

  const nbOfSepaOperations = plan.limits.sepaTransferMonth.value;
  const pricePerAdditionalSepaOperation = formatAmount({
    forcePlusSign: false,
    value: toMajorUnits(plan.fees.additionalSepaTransfer.fixed),
  });

  const sevenDaysCardLimit = formatAmount({
    forcePlusSign: false,
    value: hasAccessToBusinessCard
      ? SEVEN_DAYS_PREMIUM_PAYMENT_CARD_LIMIT
      : SEVEN_DAYS_BASIC_PAYMENT_CARD_LIMIT,
    withNoMinorUnitDigits: true,
  });
  const thirtyDaysCardLimit = formatAmount({
    forcePlusSign: false,
    value: hasAccessToBusinessCard
      ? THIRTY_DAYS_PREMIUM_PAYMENT_CARD_LIMIT
      : THIRTY_DAYS_BASIC_PAYMENT_CARD_LIMIT,
    withNoMinorUnitDigits: true,
  });

  const hasAccessToCashDeposit = isFeatureAvailable(plan, Feature.CashDeposit);
  const nbOfCashDepositsPerMonth = plan.limits.cashDepositMonth.value;
  const maxAmountPerCashDeposit = formatAmount({
    forcePlusSign: false,
    value: toMajorUnits(plan.limits.cashDepositMaxAmountPerDeposit.value),
    withNoMinorUnitDigits: true,
  });
  const hasVariableCashDepositFees = plan.fees.cashDeposit.variable !== null;

  const hasAccessToCheckDeposit = isFeatureAvailable(
    plan,
    Feature.CheckDeposit,
  );
  const nbOfCheckDepositsPerMonth = plan.limits.checkDepositMonth.value;
  const additionalCheckDepositsFees = formatAmount({
    forcePlusSign: false,
    value: toMajorUnits(plan.fees.additionalCheckDeposit.fixed),
    withNoMinorUnitDigits: true,
  });

  const hasAccessToTeamExpenses =
    isFeatureAvailable(plan, Feature.TeamManagement) &&
    (plan.limits.companyUserAccess.isUnlimited ||
      plan.limits.companyUserAccess.value > 1);

  const hasAccessToInsurancesAssistance = isFeatureAvailable(
    plan,
    Feature.InsuranceAssistance,
  );

  const hasAccessToCashflowManagementDashboard = isFeatureAvailable(
    plan,
    Feature.CashflowManagementDashboard,
  );

  const hasAccessToAdvancedBillingOptions =
    isFeatureAvailable(plan, Feature.PersonalizedLogo) &&
    isFeatureAvailable(plan, Feature.InvoicingItemsCatalog);

  const hasAccessToPhoneSupport =
    plan.customerSupport.type === CustomerSupportType.PriorityAll;

  const previousPlanName =
    plan.downgradablePlans[plan.downgradablePlans.length - 1]?.brandName ?? '';

  const secondaryBankAccountsCount = plan.limits.activeBankAccounts.value - 1;

  return (
    <SunshineCard
      borderRadius="radius-16"
      className={className}
      padding="space-0"
      width={292}
    >
      <Flex direction="column">
        <S.CardImageContainer
          $rebrandedColor="grey.100"
          brandColor={plan.brandColor}
        >
          <Image
            backgroundRepeat="no-repeat"
            height="100%"
            objectFit="cover"
            src={physicalCardImage}
            width="100%"
          />
        </S.CardImageContainer>

        <Flex
          direction="column"
          paddingBottom="space-32"
          paddingTop="space-16"
          paddingX="space-16"
        >
          <Text fontSize="font-18" fontWeight="weight-500" variant="primary">
            <FormattedMessage
              {...messages.planName}
              values={{ planName: plan.brandName }}
            />
          </Text>

          <Flex align="baseline">
            <Price
              size={4}
              value={toMajorUnits(plan.priceWithoutVAT)}
              withoutCents={plan.price % 1 === 0}
            />
            <Text marginLeft="space-6">
              <FormattedMessage {...messages.priceDetails} />
            </Text>
          </Flex>

          {isCurrentPlan ? (
            <Button isDisabled={true} marginTop="space-20" marginX="space-8">
              <FormattedMessage {...messages.currentPlan} />
            </Button>
          ) : (
            <S.UpgradeLink
              to={{
                pathname: '/upgrade/confirm',
                state: {
                  featurePreview,
                  from,
                  pricingPlan: plan,
                },
              }}
            >
              <Button>
                <FormattedMessage
                  {...messages.choosePlan}
                  values={{ planName: plan.brandName }}
                />
              </Button>
            </S.UpgradeLink>
          )}

          {catchPhrase ? (
            <Text height="space-64" marginTop="space-24" variant="primary">
              <FormattedMessage {...catchPhrase} />
            </Text>
          ) : null}

          <Spacer flex="none" height="space-8" />

          {previousPlanName ? (
            <PlanDetailsBulletPoint
              title={intl.formatMessage(messages.featuresFromPreviousPlan, {
                planName: previousPlanName,
              })}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={intl.formatMessage(
              messages.multipleAccountsDescription,
            )}
            title={intl.formatMessage(
              secondaryBankAccountsCount === 1
                ? messages.multipleAccountsTitleSingular
                : messages.multipleAccountsTitlePlural,
              { secondaryBankAccountsCount },
            )}
          />

          <PlanDetailsBulletPoint
            description={
              canHaveMultiplePhysicalCards
                ? intl.formatMessage(messages.physicalCardsDescription)
                : undefined
            }
            title={intl.formatMessage(
              hasAccessToBusinessCard
                ? messages.physicalCardsBusiness
                : messages.physicalCardsBasic,
              { nbOfCards: nbOfPhysicalCards },
            )}
          />

          {hasAccessToVirtualCards ? (
            <PlanDetailsBulletPoint
              description={intl.formatMessage(messages.virtualCardsDescription)}
              title={
                hasUnlimitedVirtualCards
                  ? intl.formatMessage(messages.virtualCardsUnlimited)
                  : intl.formatMessage(messages.virtualCardsLimited, {
                      nbOfCards: nbOfVirtualCards,
                    })
              }
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={intl.formatMessage(
              plan.id === PlanId.Business
                ? messages.sepaOperationsBusinessDescription
                : messages.sepaOperationsDescription,
              { amount: pricePerAdditionalSepaOperation },
            )}
            title={intl.formatMessage(
              plan.id === PlanId.Business
                ? messages.sepaOperationsBusinessTitle
                : messages.sepaOperationsTitle,
              { nbOfSepaOperations },
            )}
          />

          <PlanDetailsBulletPoint
            description={intl.formatMessage(messages.cardLimitDescription, {
              amount: sevenDaysCardLimit,
            })}
            title={intl.formatMessage(messages.cardLimitTitle, {
              amount: thirtyDaysCardLimit,
            })}
          />

          {hasAccessToCashDeposit ? (
            <PlanDetailsBulletPoint
              description={
                hasVariableCashDepositFees
                  ? intl.formatMessage(
                      messages.cashDepositsDescriptionVariable,
                      {
                        percentage:
                          (plan.fees.cashDeposit?.variable ?? 0) * 100,
                      },
                    )
                  : intl.formatMessage(messages.cashDepositsDescriptionFixed, {
                      amount: formatAmount({
                        forcePlusSign: false,
                        value: toMajorUnits(plan.fees.cashDeposit.fixed),
                        withNoMinorUnitDigits: true,
                      }),
                    })
              }
              title={intl.formatMessage(
                nbOfCashDepositsPerMonth === 1
                  ? messages.cashDepositsTitleSingular
                  : messages.cashDepositsTitlePlural,
                { maxAmountPerCashDeposit, nbOfCashDepositsPerMonth },
              )}
            />
          ) : null}

          {hasAccessToCheckDeposit ? (
            <PlanDetailsBulletPoint
              description={intl.formatMessage(
                messages.checkDepositDescription,
                { amount: additionalCheckDepositsFees },
              )}
              title={
                nbOfCheckDepositsPerMonth === 1
                  ? intl.formatMessage(messages.checkDepositTitleSingular)
                  : intl.formatMessage(messages.checkDepositTitlePlural, {
                      nbOfCheckDeposits: nbOfCheckDepositsPerMonth,
                    })
              }
            />
          ) : null}

          {hasAccessToTeamExpenses ? (
            <PlanDetailsBulletPoint
              description={intl.formatMessage(messages.teamExpensesDescription)}
              title={intl.formatMessage(messages.teamExpensesTitle)}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={intl.formatMessage(messages.preAccountingDescription)}
            title={intl.formatMessage(messages.preAccountingTitle)}
          />

          {hasAccessToCashflowManagementDashboard ? (
            <PlanDetailsBulletPoint
              description={intl.formatMessage(
                messages.cashflowManagementDashboardDescription,
              )}
              title={intl.formatMessage(
                messages.cashflowManagementDashboardTitle,
              )}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={intl.formatMessage(messages.invoicingToolDescription)}
            title={intl.formatMessage(messages.invoicingToolTitle)}
          />

          {hasAccessToAdvancedBillingOptions ? (
            <PlanDetailsBulletPoint
              description={intl.formatMessage(
                messages.advancedBillingOptionsDescription,
              )}
              title={intl.formatMessage(messages.advancedBillingOptionsTitle)}
            />
          ) : null}

          {hasAccessToInsurancesAssistance ? (
            <PlanDetailsBulletPoint
              description={intl.formatMessage(
                insurancesAssistanceDescriptionMessages[
                  plan.id as PlanId.Pro | PlanId.Plus | PlanId.Business
                ],
              )}
              title={intl.formatMessage(messages.insurancesAssistanceTitle)}
            />
          ) : null}

          <PlanDetailsBulletPoint
            description={intl.formatMessage(
              messages.customerSupportDescription,
            )}
            title={intl.formatMessage(messages.customerSupportTitle)}
          />

          {hasAccessToPhoneSupport ? (
            <PlanDetailsBulletPoint
              description={intl.formatMessage(messages.phoneSupportDescription)}
              title={intl.formatMessage(messages.phoneSupportTitle)}
            />
          ) : null}
        </Flex>
      </Flex>
    </SunshineCard>
  );
};

export default PlanDetails;
