import { PlanId } from '__generated__/GQL';

import basicCard from '../assets/basicCard.png';
import doubleCard from '../assets/doubleCard.png';
import plusCard from '../assets/plusCard.png';
import tripleCard from '../assets/tripleCard.png';

export const PLANS_CARD_IMAGES: Record<PlanId, string> = {
  /**
   * 2025 plans
   */
  [PlanId.FreeMonthly_2025]: basicCard,
  [PlanId.StartMonthly_2025]: basicCard,
  [PlanId.StartYearly_2025]: basicCard,
  [PlanId.PlusMonthly_2025]: basicCard,
  [PlanId.PlusYearly_2025]: basicCard,
  [PlanId.BusinessMonthly_2025]: basicCard,
  [PlanId.BusinessYearly_2025]: basicCard,
  /**
   * 2023 plans
   */
  [PlanId.Basic]: basicCard,
  [PlanId.Plus]: plusCard,
  [PlanId.Pro]: doubleCard,
  [PlanId.Business]: tripleCard,
  /**
   * Discontinued plans - for pleasing TS
   */
  [PlanId.MoralPersonBasic]: basicCard,
  [PlanId.MoralPersonBusiness]: tripleCard,
  [PlanId.MoralPersonPremium]: plusCard,
  [PlanId.PhysicalPersonBasic]: basicCard,
  [PlanId.PhysicalPersonPremium]: plusCard,
};
