import { defineMessages } from 'react-intl';

import { PlanId } from '__generated__/GQL';

export const messages = defineMessages({
  accountantAccessDescription: {
    id: 'upgrade.plan_details.accountant_access.description',
  },
  accountantAccessTitle: {
    id: 'upgrade.plan_details.accountant_access.title',
  },
  advancedBillingOptionsDescription: {
    id: 'upgrade.plan_details.advanced_billing_options.description',
  },
  advancedBillingOptionsTitle: {
    id: 'upgrade.plan_details.advanced_billing_options.title',
  },
  cardLimitDescription: {
    id: 'upgrade.plan_details.card_limit.description',
  },
  cardLimitTitle: {
    id: 'upgrade.plan_details.card_limit.title',
  },
  cashDepositsDescriptionFixed: {
    id: 'upgrade.plan_details.cash_deposits.description_fixed',
  },
  cashDepositsDescriptionVariable: {
    id: 'upgrade.plan_details.cash_deposits.description_variable',
  },
  cashDepositsTitlePlural: {
    id: 'upgrade.plan_details.cash_deposits.title_plural',
  },
  cashDepositsTitleSingular: {
    id: 'upgrade.plan_details.cash_deposits.title_singular',
  },
  cashflowManagementDashboardDescription: {
    id: 'upgrade.plan_details.cashflow_management_dashboard.description',
  },
  cashflowManagementDashboardTitle: {
    id: 'upgrade.plan_details.cashflow_management_dashboard.title',
  },
  catchPhraseBusiness: {
    id: 'upgrade.plan_details.catch_phrase.business',
  },
  catchPhrasePlus: {
    id: 'upgrade.plan_details.catch_phrase.plus',
  },
  catchPhrasePro: {
    id: 'upgrade.plan_details.catch_phrase.pro',
  },
  checkDepositDescription: {
    id: 'upgrade.plan_details.check_deposit.description',
  },
  checkDepositTitlePlural: {
    id: 'upgrade.plan_details.check_deposit.title_plural',
  },
  checkDepositTitleSingular: {
    id: 'upgrade.plan_details.check_deposit.title_singular',
  },
  choosePlan: {
    id: 'upgrade.plan_details.choose_plan',
  },
  currentPlan: {
    id: 'upgrade.plan_details.current_plan',
  },
  customerSupportDescription: {
    id: 'upgrade.plan_details.customer_support.description',
  },
  customerSupportTitle: {
    id: 'upgrade.plan_details.customer_support.title',
  },
  featuresFromPreviousPlan: {
    id: 'upgrade.plan_details.features_from_previous_plan',
  },
  insurancesAssistanceDescriptionInsurances2024Business: {
    id: 'upgrade.plan_details.insurances_assistance.description.insurances_2024.business',
  },
  insurancesAssistanceDescriptionInsurances2024Plus: {
    id: 'upgrade.plan_details.insurances_assistance.description.insurances_2024.plus',
  },
  insurancesAssistanceDescriptionInsurances2024Pro: {
    id: 'upgrade.plan_details.insurances_assistance.description.insurances_2024.pro',
  },
  insurancesAssistanceTitle: {
    id: 'upgrade.plan_details.insurances_assistance.title',
  },
  invoicingToolDescription: {
    id: 'upgrade.plan_details.invoicing_tool.description',
  },
  invoicingToolTitle: {
    id: 'upgrade.plan_details.invoicing_tool.title',
  },
  multipleAccountsDescription: {
    id: 'upgrade.plan_details.multiple_accounts.description',
  },
  multipleAccountsTitlePlural: {
    id: 'upgrade.plan_details.multiple_accounts.title_plural',
  },
  multipleAccountsTitleSingular: {
    id: 'upgrade.plan_details.multiple_accounts.title_singular',
  },
  phoneSupportDescription: {
    id: 'upgrade.plan_details.phone_support.description',
  },
  phoneSupportTitle: {
    id: 'upgrade.plan_details.phone_support.title',
  },
  physicalCardsBasic: {
    id: 'upgrade.plan_details.physical_cards.basic',
  },
  physicalCardsBusiness: {
    id: 'upgrade.plan_details.physical_cards.business',
  },
  physicalCardsDescription: {
    id: 'upgrade.plan_details.physical_cards.description',
  },
  planName: {
    id: 'upgrade.plan_details.plan_name',
  },
  preAccountingDescription: {
    id: 'upgrade.plan_details.pre_accounting.description',
  },
  preAccountingTitle: {
    id: 'upgrade.plan_details.pre_accounting.title',
  },
  priceDetails: {
    id: 'upgrade.plan_details.price_details',
  },
  sepaOperationsBusinessDescription: {
    id: 'upgrade.plan_details.sepa_operations_business.description',
  },
  sepaOperationsBusinessTitle: {
    id: 'upgrade.plan_details.sepa_operations_business.title',
  },
  sepaOperationsDescription: {
    id: 'upgrade.plan_details.sepa_operations.description',
  },
  sepaOperationsTitle: {
    id: 'upgrade.plan_details.sepa_operations.title',
  },
  teamExpensesDescription: {
    id: 'upgrade.plan_details.team_expenses.description',
  },
  teamExpensesTitle: {
    id: 'upgrade.plan_details.team_expenses.title',
  },
  virtualCardsDescription: {
    id: 'upgrade.plan_details.virtual_cards.description',
  },
  virtualCardsLimited: {
    id: 'upgrade.plan_details.virtual_cards.limited',
  },
  virtualCardsUnlimited: {
    id: 'upgrade.plan_details.virtual_cards.unlimited',
  },
} as const);

export const insurancesAssistanceDescriptionMessages = {
  [PlanId.Pro]: messages.insurancesAssistanceDescriptionInsurances2024Pro,
  [PlanId.Plus]: messages.insurancesAssistanceDescriptionInsurances2024Plus,
  [PlanId.Business]:
    messages.insurancesAssistanceDescriptionInsurances2024Business,
} as const;

export const catchPhraseMessages = {
  [PlanId.Pro]: messages.catchPhrasePro,
  [PlanId.Plus]: messages.catchPhrasePlus,
  [PlanId.Business]: messages.catchPhraseBusiness,
};
