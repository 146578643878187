import { type FC } from 'react';
import { useLocation } from 'react-router';
import { Flex, Link, Text } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import CloseButton from 'components/CloseButton';
import ErrorView from 'components/ErrorView';
import Loader from 'components/Loader';
import TrackView from 'features/Analytics/TrackView';
import { type SupportedFeaturePreview } from 'features/Upgrade/types';

import PlanDetails from '../../components/PlanDetails';
import usePlanChoice from './hooks/usePlanChoice';
import locales from './locales';
import * as S from './styles';

type LocationState = {
  featurePreview?: SupportedFeaturePreview;
  from?: string;
};

const PlanChoicePage: FC = () => {
  const { state } = useLocation<LocationState>();
  const { data, error, loading } = usePlanChoice(state?.featurePreview);

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return <ErrorView />;
  }

  const shouldShowCurrentPlanDetails =
    data.currentPlan.downgradablePlans.length > 0;

  return (
    <Flex direction="column" height="100vh">
      <TrackView pageName="PlanChoicePage" />

      <S.NavMenu borderBottom="1px solid" borderBottomColor="grey.300">
        <CloseButton />
      </S.NavMenu>

      <Flex
        alignItems="start"
        direction="column"
        overflowY="auto"
        paddingLeft={382}
        paddingRight="space-32"
        paddingTop="space-32"
      >
        <Text fontSize="font-26" fontWeight="weight-500" variant="primary">
          {locales.title}
        </Text>

        <Flex direction="column" marginTop="space-40">
          <Flex alignItems="start">
            {shouldShowCurrentPlanDetails ? (
              <PlanDetails
                featurePreview={state?.featurePreview}
                from={state?.from}
                isCurrentPlan={true}
                plan={data.currentPlan}
              />
            ) : null}
            {data.upgradablePlans.map((plan) => (
              <S.SpacedPlanDetails
                featurePreview={state?.featurePreview}
                from={state?.from}
                key={plan.id}
                plan={plan}
              />
            ))}
          </Flex>

          <S.BottomMenu borderTop="1px solid" borderTopColor="grey.300">
            <Link
              as="a"
              href="https://www.shine.fr/tarifs"
              marginBottom="space-16"
              rel="noopener noreferrer"
              target="_blank"
              textDecoration="none"
            >
              <Button icon="link" variant="secondary">
                {locales.seePlansDetails}
              </Button>
            </Link>
          </S.BottomMenu>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PlanChoicePage;
