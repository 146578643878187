import { FormattedMessage } from 'react-intl';
import { getTokens, Typography, XStack } from '@shinetools/sunshine-universal';

import { type FCWithChildren } from 'common/types';
import formatPrice from 'helpers/price/formatPrice';

type PlanPriceProps = {
  price: number;
  // To handle display of discount with a strikethrough price
  regularPrice?: number;
  per?: 'month' | 'year';
  variant?: 'large' | 'small';
};

export const PlanPrice: FCWithChildren<PlanPriceProps> = ({
  per,
  price,
  regularPrice = price,
  variant = 'large',
}) => {
  const lineHeightLarge = getTokens().size['$text.lineHeight.large'].variable;

  const strikethroughPriceComponent =
    variant === 'large' ? (
      <Typography.HeaderNumber
        $platform-web={{ lineHeight: lineHeightLarge }}
        textDecorationLine="line-through"
      >
        {formatPrice(regularPrice)}
      </Typography.HeaderNumber>
    ) : (
      <Typography.Text bold size="large" textDecorationLine="line-through">
        {formatPrice(regularPrice)}
      </Typography.Text>
    );

  const priceComponent =
    variant === 'large' ? (
      <Typography.HeaderNumber
        $platform-web={{ lineHeight: lineHeightLarge }}
        size="veryLarge"
      >
        {formatPrice(price)}
      </Typography.HeaderNumber>
    ) : (
      <Typography.Text bold size="large">
        {formatPrice(price)}
      </Typography.Text>
    );

  return (
    <XStack
      alignItems="baseline"
      gap={variant === 'large' ? '$space.8' : '$space.4'}
    >
      {price < regularPrice ? strikethroughPriceComponent : null}
      {priceComponent}
      <Typography.Text bold={variant === 'small'} size="large">
        {(() => {
          if (per === 'month') {
            return (
              <FormattedMessage
                defaultMessage="/ mois HT"
                id="components.plan_price.per_month_excluding_tax"
              />
            );
          }

          if (per === 'year') {
            return (
              <FormattedMessage
                defaultMessage="/ an HT"
                id="components.plan_price.per_year_excluding_tax"
              />
            );
          }

          return (
            <FormattedMessage
              defaultMessage="HT"
              id="components.plan_price.excluding_tax"
            />
          );
        })()}
      </Typography.Text>
    </XStack>
  );
};
