import { sanitizeUrl } from '@braintree/sanitize-url';

import logger from '../../helpers/logger';
import { OAuthBadRedirectURIError } from './errors';

const sanitizeOrStripUri = (redirectUri: string): string => {
  try {
    /**
     * Here we use `sanitizeUrl` function from the `@braintree/sanitize-url` package to sanitize the redirectUri.
     * but the function URL.canParse() is not defined on all browsers, so we need to wrap it in a try/catch block.
     * Package issue : https://github.com/braintree/sanitize-url/pull/80
     */
    return sanitizeUrl(redirectUri);
  } catch (e) {
    // try to create a new URL object to check if the URL is valid
    const newUri = new URL(redirectUri);
    // we remove all the query params since we can't trust them
    for (const [key] of newUri.searchParams.entries()) {
      newUri.searchParams.delete(key);
    }

    return newUri.toString();
  }
};

export const cleanRedirectUri = (redirectUri: string): string => {
  try {
    return sanitizeOrStripUri(redirectUri);
  } catch (e) {
    logger.warn('[OAuth] Error on sanitizing redirectUri :', {
      error: e,
      redirectUri,
    });
    throw new OAuthBadRedirectURIError(
      '[OAuth] Redirect URI to partner is invalid',
    );
  }
};
