import { type FC } from 'react';
import { Grid } from '@chakra-ui/react';
import { XStack, YStack } from '@shinetools/sunshine-universal';

import SunshineCard from 'components/_core/SunshineCard';
import Skeleton from 'components/Skeleton';
import SkeletonText from 'components/SkeletonText';

const PlanRecapSkeleton: FC = () => (
  <SunshineCard.Group maxWidth={540} overflow="hidden">
    <SunshineCard
      backgroundColor="grey.100"
      borderRadius="radius-0"
      overflow="hidden"
      padding="0!"
    >
      <Grid gridTemplateColumns="1fr auto">
        <YStack
          gap="$space.8"
          paddingLeft="$space.32"
          paddingVertical="$space.32"
        >
          <SkeletonText skeletonHeight="24" width="120px" />
          <SkeletonText width="120px" />
        </YStack>

        <Skeleton
          height={70}
          marginRight="space-24"
          marginTop="auto"
          width={180}
        />
      </Grid>
    </SunshineCard>

    <SunshineCard paddingX="space-32" paddingY="space-32!">
      <SkeletonText width="240px" />

      <YStack gap="$space.32" marginTop="$space.32">
        {[0, 1, 2, 3].map((item) => (
          <XStack alignItems="center" gap="$space.16" key={item}>
            <Skeleton borderRadius="radius-8" height={32} width={32} />
            <YStack gap="$space.4">
              <SkeletonText width="320px" />
              <SkeletonText width="320px" />
            </YStack>
          </XStack>
        ))}
      </YStack>
    </SunshineCard>
  </SunshineCard.Group>
);

export default PlanRecapSkeleton;
