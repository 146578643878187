import queryString from 'query-string';

import locales from './accessItemLocales';
import { type AccessItem, type Queries } from './types';

export const hasInvalidParams = ({
  client_id: clientId,
  redirect_uri: redirectUri,
  scope,
}: Queries): boolean => !(clientId && scope && redirectUri);

export const parseQueryString = (): Queries =>
  queryString.parse(window.location.search) as unknown as Queries;

export const initCap = (lower: string): string =>
  // @ts-expect-error Object is possibly 'undefined'.
  `${lower[0].toUpperCase()}${lower.slice(1)}`;

export const sanityzeScope = (scope: string): string =>
  scope
    .split(':')
    .map((item: string, index: number): string =>
      index === 0 ? item : initCap(item),
    )
    .join('');

export const getAccessItems = (scope: string): AccessItem[] =>
  scope
    .split(' ')
    .filter((it: string): boolean => it !== 'openid')
    .map((item: string): AccessItem => {
      const accessItem: AccessItem = locales.accessItems[
        sanityzeScope(item)
      ] as AccessItem;

      return {
        id: item,
        label: accessItem.label,
        tooltip: accessItem.tooltip,
      };
    });
