export class InvalidUuidError extends Error {
  constructor(message = 'Invalid UUID') {
    super(message);
  }
}

export class OAuthBadRedirectURIError extends Error {
  constructor(message = 'Invalid redirect URI') {
    super(message);
  }
}
