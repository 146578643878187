import { type FC } from 'react';
import { FormattedNumber } from 'react-intl';

interface FormattedPriceProps {
  valueInCents: number;
}

/**
 * Format a price in minor currency (e.g. 100 = 1€)
 * Will show `cents` only if the price in euros is not an integer.
 */
export const FormattedPrice: FC<FormattedPriceProps> = ({ valueInCents }) => {
  const isInteger = valueInCents % 100 === 0;

  return (
    <FormattedNumber
      currency="EUR"
      maximumFractionDigits={isInteger ? undefined : 2}
      minimumFractionDigits={isInteger ? 0 : 2}
      style="currency"
      value={valueInCents / 100}
    />
  );
};
