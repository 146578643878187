import { type FC, type ReactNode, type RefObject } from 'react';
import { Box, SimpleGrid, type SimpleGridProps, Slide } from '@chakra-ui/react';
import { useToken } from '@chakra-ui/system';

import useIsVisible from 'common/hooks/useIsVisible';

type PlanStickyCardsProps = SimpleGridProps & {
  items: ReactNode[];
  planCardsRef: RefObject<Element>;
};

export const PlanStickyCards: FC<PlanStickyCardsProps> = ({
  items,
  planCardsRef,
  ...props
}) => {
  const isVisible = useIsVisible(planCardsRef);
  const [sticky] = useToken('zIndices', ['sticky']);

  return (
    <Slide direction="top" in={!isVisible} style={{ zIndex: sticky }}>
      <SimpleGrid
        alignItems="center"
        background="white"
        columns={items.length + 1}
        gap="space-16"
        marginX="auto"
        paddingTop="space-40"
        {...props}
      >
        {items.map((item, index) => (
          <Box gridColumnStart={index + 2} key={index}>
            {item}
          </Box>
        ))}
      </SimpleGrid>
    </Slide>
  );
};
