import {
  Feature,
  isFeatureAvailable,
  PaymentCardType,
} from '@shinetools/pricing-plan-library';

import { PlanTier, SupportLevelType } from '__generated__/GQL';
import formatPercent from 'common/formatPercent';
import formatPrice from 'helpers/price/formatPrice';

import {
  PricingTableRow,
  PricingTableSection,
  type PricingTableSectionItem,
} from './types';

const isUnlimitedValue = ({ value }: { value: unknown }) =>
  value === 2147483647;

export const tableSections: PricingTableSectionItem[] = [
  {
    key: PricingTableSection.AccountAndCards,
    rows: [
      {
        getCellData: (plan) => ({
          text: plan.limits.activeBankAccounts.value.toString(10),
        }),
        key: PricingTableRow.IbanAccounts,
      },
      {
        getCellData: (plan, intl) => ({
          text:
            plan.paymentCard.type === PaymentCardType.BasicDebitCard
              ? intl.formatMessage(
                  {
                    id: 'onboarding.pricing_plan_2025.selection.row.physical_cards.basic_debit',
                  },
                  { quantity: plan.limits.physicalPaymentCard.value },
                )
              : intl.formatMessage(
                  {
                    id: 'onboarding.pricing_plan_2025.selection.row.physical_cards.business_world_debit',
                  },
                  { quantity: plan.limits.physicalPaymentCard.value },
                ),
        }),
        key: PricingTableRow.PhysicalCards,
      },
      {
        getCellData: (plan, intl) => ({
          text: plan.limits.virtualPaymentCardMonth.isUnlimited
            ? intl.formatMessage({
                id: 'onboarding.pricing_plan_2025.selection.row.virtual_cards.unlimited',
              })
            : intl.formatMessage(
                {
                  id: 'onboarding.pricing_plan_2025.selection.row.virtual_cards.cell',
                },
                {
                  amount: formatPrice(
                    plan.fees.additionalVirtualCardMonth.fixed,
                  ),
                },
              ),
        }),
        key: PricingTableRow.VirtualCards,
      },
      {
        getCellData: (plan) => ({
          text: plan.limits.sepaTransferMonth.value.toString(10),
        }),
        key: PricingTableRow.SepaTransfers,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.InstantTransfers,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.incoming_international_transfers.cell',
            },
            {
              amount: formatPrice(plan.fees.swiftTransferPayin.fixed),
            },
          ),
        }),
        key: PricingTableRow.IncomingInternationalTransfers,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.outgoing_international_transfers.cell',
            },
            {
              amount: formatPrice(
                plan.fees.internationalTransferPayout.minimum ?? NaN,
              ),
              percent: formatPercent(
                plan.fees.internationalTransferPayout.variable ?? NaN,
              ),
            },
          ),
        }),
        key: PricingTableRow.OutgoingInternationalTransfers,
      },
      {
        getCellData: (plan) => ({
          isChecked: plan.limits.transfersPerBulk.value > 0,
        }),
        key: PricingTableRow.BulkTransfers,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.card_payment_abroad.cell',
            },
            {
              percent: formatPercent(plan.fees.cardPayoutInter.variable ?? NaN),
            },
          ),
        }),
        key: PricingTableRow.CardPaymentAbroad,
      },
      {
        getCellData: (plan) => ({
          text: formatPrice(
            plan.limits.physicalPaymentCardLimitsMonthlySlidingWindow.value,
          ),
        }),
        key: PricingTableRow.CardPaymentLimit,
      },
      {
        getCellData: (plan, intl) => ({
          text: (
            plan.limits.withdrawalMonth.value ||
            intl.formatMessage({
              id: 'onboarding.pricing_plan_2025.selection.row.cash_withdrawals.optional',
            })
          ).toString(),
        }),
        key: PricingTableRow.CashWithdrawals,
      },
      {
        getCellData: (plan) => ({
          text: formatPrice(
            plan.limits.withdrawalLimitsMonthlySlidingWindow.value,
          ),
        }),
        key: PricingTableRow.WithdrawalLimit,
      },
      {
        getCellData: (plan, intl) => ({
          text:
            plan.limits.checkDepositMonth.value.toString(10) ||
            intl.formatMessage({
              id: 'onboarding.pricing_plan_2025.selection.row.check_deposit.optional',
            }),
        }),
        key: PricingTableRow.CheckDeposit,
      },
      {
        getCellData: (plan, intl) => ({
          text: intl.formatMessage(
            {
              id: 'onboarding.pricing_plan_2025.selection.row.cash_deposit.cell',
            },
            { percent: formatPercent(plan.fees.cashDeposit.variable ?? NaN) },
          ),
        }),
        key: PricingTableRow.CashDeposit,
      },
    ],
  },
  {
    key: PricingTableSection.Invoicing,
    rows: [
      {
        getCellData: (plan, intl) => ({
          text: plan.limits.invoicingMonth.isUnlimited
            ? intl.formatMessage({
                id: 'onboarding.pricing_plan_2025.selection.row.invoicing_tool.unlimited',
              })
            : intl.formatMessage(
                {
                  id: 'onboarding.pricing_plan_2025.selection.row.invoicing_tool.cell',
                },
                { quantity: plan.limits.invoicingMonth.value },
              ),
        }),
        key: PricingTableRow.InvoicingTool,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.PersonalizedLogo),
        }),
        key: PricingTableRow.CustomisedQuotesAndInvoices,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InvoicingItemsCatalog),
        }),
        key: PricingTableRow.ProductAndServicesCatalogue,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.AutoReminder,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.InvoiceCardPayment,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.PaymentTerminal,
      },
    ],
  },
  {
    key: PricingTableSection.TrackingAndPreAccounting,
    rows: [
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(
            plan,
            Feature.CashflowManagementDashboard,
          ),
        }),
        key: PricingTableRow.CashflowManagementDashboard,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.UrssafEstimator),
        }),
        key: PricingTableRow.UrssafEstimator,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.AccountantAccess),
        }),
        key: PricingTableRow.AccountantAccess,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.AccountantExports,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.Receipts,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.AccountingSoftwareIntegration,
      },
    ],
  },
  {
    key: PricingTableSection.TeamExpenseManagement,
    rows: [
      {
        getCellData: (plan, intl) => {
          if (!plan.limits.companyUserAccess.value) {
            return { isChecked: false };
          }
          if (
            plan.limits.companyUserAccess.isUnlimited &&
            isUnlimitedValue(plan.limits.companyUserAccess)
          ) {
            return {
              text: intl.formatMessage({
                id: 'onboarding.pricing_plan_2025.selection.row.employee_access.unlimited',
              }),
            };
          }
          return {
            text: intl.formatMessage(
              {
                id: 'onboarding.pricing_plan_2025.selection.row.employee_access.cell',
              },
              {
                amount: formatPrice(plan.fees.extraSeatMonth.fixed),
                quantity: plan.limits.companyUserAccess.value,
              },
            ),
          };
        },
        key: PricingTableRow.EmployeeAccess,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.TeamManagement),
        }),
        key: PricingTableRow.ExpenseAccountManagement,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.TeamManagement),
        }),
        key: PricingTableRow.EmployeeCards,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.TeamManagement),
        }),
        key: PricingTableRow.EmployeeTransfers,
      },
    ],
  },
  {
    key: PricingTableSection.InsuranceAndSupport,
    rows: [
      {
        getCellData: (plan, intl) => {
          return {
            text:
              plan.customerSupport.urgentRequestsSupportLevel ===
              SupportLevelType.NormalPriority
                ? intl.formatMessage({
                    id: 'onboarding.pricing_plan_2025.selection.row.online_support.standard',
                  })
                : intl.formatMessage({
                    id: 'onboarding.pricing_plan_2025.selection.row.online_support.priority',
                  }),
          };
        },
        key: PricingTableRow.OnlineSupport,
      },
      {
        getCellData: (plan, intl) => {
          if (!plan.customerSupport?.phoneSupportLevel) {
            return { isChecked: false };
          }
          if (
            plan.customerSupport.phoneSupportLevel ===
            SupportLevelType.StandardPriority
          ) {
            return {
              text: intl.formatMessage({
                id: 'onboarding.pricing_plan_2025.selection.row.phone_support.standard',
              }),
            };
          }
          return {
            text: intl.formatMessage({
              id: 'onboarding.pricing_plan_2025.selection.row.phone_support.priority',
            }),
          };
        },

        key: PricingTableRow.PhoneSupport,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InsuranceAssistance),
        }),
        key: PricingTableRow.LegalAssistance,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InsuranceAssistance),
        }),
        key: PricingTableRow.DebtCollectionAssistance,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InsuranceAssistance),
        }),
        key: PricingTableRow.AccidentInsurance,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InsuranceAssistance),
        }),
        key: PricingTableRow.MobileInsurance,
      },
      {
        getCellData: (plan) => ({
          isChecked: plan.tier === PlanTier.Business,
        }),
        key: PricingTableRow.LaptopInsurance,
      },
      {
        getCellData: (plan) => ({
          isChecked: plan.tier === PlanTier.Business,
        }),
        key: PricingTableRow.PurchaseProtection,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InsuranceAssistance),
        }),
        key: PricingTableRow.TransportDelays,
      },
      {
        getCellData: (plan) => ({
          isChecked: plan.tier === PlanTier.Business,
        }),
        key: PricingTableRow.HealthcareAbroad,
      },
      {
        getCellData: (plan) => ({
          isChecked: plan.tier === PlanTier.Business,
        }),
        key: PricingTableRow.RentalCar,
      },
      {
        getCellData: (plan) => ({
          isChecked: isFeatureAvailable(plan, Feature.InsuranceAssistance),
        }),
        key: PricingTableRow.FraudInsurance,
      },
      {
        getCellData: (plan) => ({
          isChecked: plan.tier === PlanTier.Business,
        }),
        key: PricingTableRow.PhishingProtection,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.ContractLibrary,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.Toolbox,
      },
      {
        getCellData: () => ({
          isChecked: true,
        }),
        key: PricingTableRow.PartnerNetwork,
      },
    ],
  },
];
