import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import { type PlanId } from '__generated__/GQL';
import Button from 'components/_core/Button';
import Modal from 'components/_core/Modal';
import SquircleIcon from 'components/_core/SunshineSquircleIcon';
import { bankAccounts } from 'features/Bank/routes';

import { type PlansMetadata } from '../libs/plansMetadata';

export type DowngradeBlockerReason =
  /**
   * A dialog preventing you from downgrading because you have too much active wallets for the target plan.
   */
  | {
      type: 'bank-account-limit';
      currentWalletCount: number;
      newLimit: number;
      targetPlanName: string;
    }
  /**
   * A dialog reminding you the features you're going to lose if you proceed.
   */
  | {
      type: 'discourager';
      lostFeatures: NonNullable<
        PlansMetadata[PlanId]['lostFeaturesOnDowngrade'][PlanId]
      >;
      continueRoute: string;
      targetPlanName: string;
    }
  /**
   * A dialog reminding you that you’re late on payment and you can’t change your plan until you pay.
   */
  | {
      type: 'late-on-payment';
      targetPlanName: string;
    };

const DowngradeBlockerMultiWallets = ({
  downgradeBlockerReason: { currentWalletCount, newLimit, targetPlanName },
}: {
  downgradeBlockerReason: DowngradeBlockerReason & {
    type: 'bank-account-limit';
  };
}) => {
  return (
    <>
      <Modal.Body>
        <Heading marginBottom="space-32" size="lg">
          <FormattedMessage
            id="subscription.plan_downgrade_blocker.too_much_wallets.title"
            values={{ targetPlanName }}
          />
        </Heading>

        <VStack align="flex-start" spacing="space-32">
          <Typography.Text variant="secondary">
            <FormattedMessage
              id="subscription.plan_downgrade_blocker.bank_account_limit.description"
              values={{
                currentWalletCount,
                excessWalletCount: currentWalletCount - newLimit,
                newWalletLimit: newLimit,
              }}
            />
          </Typography.Text>
        </VStack>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-end">
        <Button
          as={Link}
          icon="arrow-right"
          iconPosition="right"
          to={bankAccounts}
        >
          <FormattedMessage id="subscription.plan_downgrade_blocker.bank_account_limit.cta" />
        </Button>
      </Modal.Footer>
    </>
  );
};

const DowngradeBlockerDiscourager = ({
  downgradeBlockerReason: { continueRoute, lostFeatures, targetPlanName },
}: {
  downgradeBlockerReason: DowngradeBlockerReason & {
    type: 'discourager';
  };
}) => {
  return (
    <>
      <Modal.Body>
        <VStack align="stretch" marginBottom="space-40" spacing="space-16">
          <Heading size="lg">
            <FormattedMessage
              id="subscription.plan_downgrade_blocker.discourager.title"
              values={{ targetPlanName }}
            />
          </Heading>

          <Text>
            <FormattedMessage id="subscription.plan_downgrade_blocker.discourager.subtitle" />
          </Text>
        </VStack>

        <VStack align="stretch" spacing="space-32">
          {lostFeatures.map(({ description, icon }) => {
            const descriptionNode = description();

            return (
              <HStack key={icon} spacing="space-16">
                <SquircleIcon name={icon} />
                <Typography.Text>{descriptionNode}</Typography.Text>
              </HStack>
            );
          })}
        </VStack>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-end">
        <Button as={Link} to={continueRoute}>
          <FormattedMessage
            id="subscription.plan_downgrade_blocker.discourager.cta"
            values={{ targetPlanName }}
          />
        </Button>
      </Modal.Footer>
    </>
  );
};

const DowngradeBlockerLateOnPayment = ({
  downgradeBlockerReason: { targetPlanName },
  onClose,
}: {
  downgradeBlockerReason: DowngradeBlockerReason & {
    type: 'late-on-payment';
  };
  onClose: () => void;
}) => {
  return (
    <>
      <Modal.Body>
        <Heading marginBottom="space-32" size="lg">
          <FormattedMessage
            id="subscription.plan_downgrade_blocker.late_on_payment.title"
            values={{ targetPlanName }}
          />
        </Heading>

        <VStack align="flex-start" spacing="space-32">
          <Typography.Text variant="secondary">
            <FormattedMessage id="subscription.plan_downgrade_blocker.late_on_payment.description" />
          </Typography.Text>
        </VStack>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-end">
        <Button onClick={() => onClose()}>
          <FormattedMessage id="subscription.plan_downgrade_blocker.late_on_payment.cta" />
        </Button>
      </Modal.Footer>
    </>
  );
};

export const DowngradeBlockerDialog = ({
  downgradeBlockerReason,
  onClose,
}: {
  downgradeBlockerReason: DowngradeBlockerReason | null;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={!!downgradeBlockerReason} onClose={onClose}>
      <Modal.Header />

      {(() => {
        switch (downgradeBlockerReason?.type) {
          case undefined:
            return null;

          case 'bank-account-limit':
            return (
              <DowngradeBlockerMultiWallets
                downgradeBlockerReason={downgradeBlockerReason}
              />
            );

          case 'discourager':
            return (
              <DowngradeBlockerDiscourager
                downgradeBlockerReason={downgradeBlockerReason}
              />
            );

          case 'late-on-payment':
            return (
              <DowngradeBlockerLateOnPayment
                downgradeBlockerReason={downgradeBlockerReason}
                onClose={onClose}
              />
            );

          default:
            return null;
        }
      })()}
    </Modal>
  );
};
