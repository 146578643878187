import { type TypePolicies, type TypePolicy } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

type KeyField = NonNullable<TypePolicy['keyFields']>;

interface KeyFieldObject {
  keyFields: KeyField;
}

function getKeyFields(keyField: string): KeyFieldObject {
  return {
    keyFields: [keyField],
  };
}

function getEdgeKeyFields(keyField: string): KeyFieldObject {
  return {
    keyFields: (edge) => (edge?.node as Record<string, string>)[keyField],
  };
}

const typePolicies: TypePolicies = {
  AccountingExport: getKeyFields('accountingExportId'),
  AccountingExportRecipient: getKeyFields('accountingExportRecipientId'),
  AccountingRecurringExport: getKeyFields('accountingRecurringExportId'),
  ActionRequest: getKeyFields('authenticationActionRequestId'),
  Application: getKeyFields('applicationId'),
  AuthenticationDevice: getKeyFields('authenticationDeviceId'),
  BankAccount: getKeyFields('bankAccountId'),
  BankTransfer: getKeyFields('bankTransferId'),
  BankTransferRecipient: getKeyFields('bankTransferRecipientId'),
  BankTransferRequest: getKeyFields('teamMembersRequestId'),
  CanAccessFeature: {
    // Only one instance of CanAccessFeature
    keyFields: () => 'CanAccessFeature',
  },
  CapitalDeposit: getKeyFields('capitalDepositId'),
  Client: getKeyFields('clientId'),
  ClientContact: getKeyFields('clientContactId'),
  Company: {
    fields: {
      catalogItems: relayStylePagination(['companyProfileId', 'filters']),
      disputableTransactions: relayStylePagination([
        'transactionId',
        'filters',
      ]),
    },
    ...getKeyFields('companyProfileId'),
  },
  CompanyCreationActivityQuestion: {
    keyFields: false,
  },
  CompanyCreationSASU: getKeyFields('companyProfileId'),
  CompanyInvitation: getKeyFields('companyInvitationId'),
  CompanyLegalBeneficiary: getKeyFields('companyLegalBeneficiaryId'),
  CompanyMetadata: getKeyFields('companyProfileId'),
  CompanyProfile: getKeyFields('companyProfileId'),
  CompanySettings: getKeyFields('companyProfileId'),
  CompanySupposedBeneficiary: getKeyFields('supposedBeneficiaryId'),
  CompanyUser: getKeyFields('companyUserId'),
  ComputedTaxForecast: getKeyFields('taxForecastId'),
  CreditCard: getKeyFields('creditCardId'),
  CreditCardInterface: getKeyFields('creditCardId'),
  CreditCardPendingOrder: getKeyFields('creditCardId'),
  Device: getKeyFields('deviceId'),
  DirectDebitMandate: getKeyFields('directDebitMandateId'),
  ExpenseClaimRequest: getKeyFields('teamMembersRequestId'),
  Invoice: getKeyFields('invoiceId'),
  InvoiceClient: getKeyFields('invoiceClientId'),
  InvoiceSettings: getKeyFields('invoiceSettingsId'),
  InvoiceTask: getKeyFields('invoiceTaskId'),
  InvoiceUser: getKeyFields('invoiceUserId'),
  KybDocument: getKeyFields('kybDocumentId'),
  Kyc: getKeyFields('uid'),
  KycDocument: getKeyFields('kycDocumentId'),
  KycReview: getKeyFields('kycReviewId'),
  KyxCheck: getKeyFields('kyxCheckId'),
  OnboardingCompanyProfile: getKeyFields('applicationId'),
  OutstandingAgreement: getKeyFields('agreementId'),
  Package: getKeyFields('packageId'),
  PaymentLink: getKeyFields('paymentLinkId'),
  PhysicalPaymentCardOrderRequest: getKeyFields('teamMembersRequestId'),
  Plan: getKeyFields('planId'),
  Query: {
    fields: {
      shineRecurringAccountingExportSetting: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'ShineRecurringAccountingExportSettings',
            accountingExportSettingsId: args?.accountingExportSettingsId,
          });
        },
      },
      shineStartDocument(_, { args, toReference }) {
        return toReference({
          __typename: 'ShineStartDocument',
          shineStartDocumentId: args?.shineStartDocumentId,
        });
      },
      toolboxOffers: relayStylePagination(['filters']),
    },
  },
  Quote: getKeyFields('quoteId'),
  RawSelectedActivities: {
    keyFields: false,
  },
  Receipt: getKeyFields('receiptId'),
  ShineRecurringAccountingExportSettings: getKeyFields(
    'accountingExportSettingsId',
  ),
  ShineStart: getKeyFields('shineStartId'),
  ShineStartDocument: getKeyFields('shineStartDocumentId'),
  SubscriptionPlan: {
    fields: {
      quotas: { merge: false },
    },
    ...getKeyFields('subscriptionId'),
  },
  TaxForecastPeriod: getKeyFields('taxForecastPeriodId'),
  TaxForecastPeriodWithTaxes: getKeyFields('taxForecastPeriodId'),
  TaxForecastProfile: getKeyFields('taxForecastProfileId'),
  TaxResidency: getKeyFields('taxResidencyId'),
  TeamMember: getKeyFields('companyUserOrInvitationId'),
  TeamMemberConnectionEdge: getEdgeKeyFields('companyUserOrInvitationId'),
  TeamMembersRequestsConnectionEdge: getEdgeKeyFields('teamMembersRequestId'),
  ThirdPartyClient: getKeyFields('authenticationClientId'),
  ToolboxOffer: getKeyFields('toolboxOfferId'),
  ToolboxOfferConnectionEdge: getEdgeKeyFields('toolboxOfferId'),
  ToolboxPartner: getKeyFields('toolboxPartnerId'),
  Transaction: getKeyFields('transactionId'),
  TransactionEdge: getEdgeKeyFields('transactionId'),
  UserMetadata: getKeyFields('uid'),
  UserProfile: getKeyFields('uid'),
  UserSettings: getKeyFields('uid'),
  VatAmount: getKeyFields('vatAmountId'),
  VatAmountItem: getKeyFields('vatAmountItemId'),
  Viewer: {
    fields: {
      webViewOnboarding: { merge: true },
    },
    ...getKeyFields('uid'),
  },
  VirtualBudgetCardOrderRequest: getKeyFields('teamMembersRequestId'),
  VirtualPaymentCardOrderRequest: getKeyFields('teamMembersRequestId'),
};

export default typePolicies;
