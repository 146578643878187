import { type FC } from 'react';
import {
  SkeletonCircle as ChakraSkeletonCircle,
  type SkeletonProps,
} from '@chakra-ui/react';

const SkeletonCircle: FC<SkeletonProps> = (props) => (
  <ChakraSkeletonCircle endColor="grey.200" startColor="grey.200" {...props} />
);

export default SkeletonCircle;
