import type * as GQL from '../graphql/overview.gql';

type NoScheduledDowngrade = {
  hasScheduledDowngrade: false;
};

type ScheduledDowngrade = {
  hasScheduledDowngrade: true;
  nextStartingAt: Date;
  nextPlanName: string;
  currentPlanName: string;
};

export const getScheduledDowngrade = (
  data?: GQL.SubscriptionManagementOverviewQuery,
): NoScheduledDowngrade | ScheduledDowngrade => {
  const subscription = data?.viewer.company.subscriptionPlan;
  const currentPlan = data?.viewer.company.currentPlan;

  if (
    !subscription ||
    !subscription.scheduledDowngrade ||
    !subscription.nextBillingAt ||
    !currentPlan
  ) {
    return {
      hasScheduledDowngrade: false,
    };
  }

  return {
    currentPlanName: currentPlan.brandName,
    hasScheduledDowngrade: true,
    nextPlanName:
      data.plans.find((plan) => plan.id === subscription.scheduledDowngrade)
        ?.brandName ?? subscription.scheduledDowngrade,
    nextStartingAt: new Date(subscription.nextBillingAt),
  };
};
