import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, XStack, YStack } from '@shinetools/sunshine-universal';

import { type PricingPlan } from '../../../../../../../__generated__/GQL';
import formatPercent from '../../../../../../../common/formatPercent';
import { getYearlySavings } from '../../../../../../../common/plans/pricing';
import { FormattedPrice } from '../../../../../../../components/FormattedPrice';

interface YearlyPlanProps {
  monthlyPlan?: PricingPlan;
  yearlyPlan: PricingPlan;
}

export const YearlyPlan: FC<YearlyPlanProps> = ({
  monthlyPlan,
  yearlyPlan,
}) => {
  const yearlySavings = getYearlySavings(monthlyPlan, yearlyPlan);

  return (
    <>
      <YStack gap="$space.4">
        <XStack gap="$space.6">
          {monthlyPlan ? (
            <Typography.Text
              bold
              textDecorationLine="line-through"
              variant="discreet"
            >
              <FormattedPrice
                valueInCents={monthlyPlan.pricing.taxExcluded * 12}
              />
            </Typography.Text>
          ) : null}

          <Typography.Text bold>
            <FormattedMessage
              id="subscription.plans_details.plan_options.yearly.price"
              values={{
                price: (
                  <FormattedPrice
                    valueInCents={yearlyPlan.pricing.taxExcluded}
                  />
                ),
              }}
            />
          </Typography.Text>
        </XStack>

        <Typography.Text bold size="small" variant="success">
          <FormattedMessage
            id="subscription.plans_details.plan_options.save_percent"
            values={{
              percent: formatPercent(yearlySavings.percentageSaved, 0),
            }}
          />
        </Typography.Text>
      </YStack>
    </>
  );
};
