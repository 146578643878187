import { createIntl, type IntlShape, type MessageDescriptor } from 'react-intl';

/**
 * Like `intl.formatMessage` but won’t show the key if the locale doesn’t exist.
 * Instead, returns undefined.
 */
export const optionalFormatMessage = (
  intl: IntlShape,
  { id }: { id: MessageDescriptor['id'] | string },
) => {
  const silentIntl = createIntl({
    ...intl,
    onError: () => {},
    onWarn: () => {},
  });

  const string = silentIntl.formatMessage({ id } as MessageDescriptor);

  if (string === id) {
    return undefined;
  }

  return string;
};
