import { type Duration, intervalToDuration, isAfter } from 'date-fns';

import { type SubscriptionPlan } from '__generated__/GQL';

type TrialPeriodLoading = {
  isOngoing: false;
  duration: null;
  isLoading: true;
};

type TrialPeriodOngoing = {
  isOngoing: true;
  isLoading: false;
  duration: Duration;
};

type TrialPeriodEnded = {
  isOngoing: false;
  isLoading: false;
  duration: null;
};

type GenericSubscriptionPlan = Pick<
  SubscriptionPlan,
  'freePeriodEndAt' | 'trialPeriodEndDate'
>;

export const getTrialPeriod = <T extends GenericSubscriptionPlan>(
  subscriptionPlan?: T | null | undefined,
): TrialPeriodOngoing | TrialPeriodEnded | TrialPeriodLoading => {
  if (!subscriptionPlan) {
    return {
      duration: null,
      isLoading: true,
      isOngoing: false,
    };
  }

  // We are using freePeriodEndAt when a trial is offered via coupons (e.g. for 2023 plans)
  // We are using trialPeriodEndDate when a trial is offered directly via ChargeBee (e.g. for 2025 plans)
  const endOfTrialPeriod =
    subscriptionPlan.trialPeriodEndDate || subscriptionPlan.freePeriodEndAt;

  if (!endOfTrialPeriod) {
    return {
      duration: null,
      isLoading: false,
      isOngoing: false,
    };
  }

  const endDate = new Date(endOfTrialPeriod);

  if (isAfter(new Date(), endDate)) {
    return {
      duration: null,
      isLoading: false,
      isOngoing: false,
    };
  }

  const duration = intervalToDuration({
    end: endDate,
    start: new Date(),
  });

  return {
    duration,
    isLoading: false,
    isOngoing: true,
  };
};
