import { type ComponentProps } from 'react';
import { Table, TableCaption, Tbody } from '@chakra-ui/react';
import { Typography } from '@shinetools/sunshine-universal';

import { Row } from './components/Row';

type PlanTableProps = {
  title: string;
  rows: ComponentProps<typeof Row>[];
};

export function PlanTable({ rows, title }: PlanTableProps) {
  return (
    <Table layout="fixed" width="full">
      <TableCaption paddingY="space-24" placement="top" textAlign="left">
        <Typography.Text bold size="large">
          {title}
        </Typography.Text>
      </TableCaption>
      <Tbody>
        {rows.map(({ data, subtitle, tag, title: rowTitle, tooltip }) => (
          <Row
            data={data}
            key={rowTitle}
            subtitle={subtitle}
            tag={tag}
            title={rowTitle}
            tooltip={tooltip}
          />
        ))}
      </Tbody>
    </Table>
  );
}
