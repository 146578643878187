import { type PricingPlan, SubscriptionPlanFrequency } from '__generated__/GQL';

type GenericPricingPlan =
  | Pick<PricingPlan, 'pricing' | 'billingFrequency'>
  | null
  | undefined;

export const getYearlyPricing = <T extends GenericPricingPlan>(plan: T) => {
  if (!plan) {
    return {
      taxExcluded: 0,
      taxIncluded: 0,
    };
  }

  const { taxExcluded, taxIncluded } = plan.pricing;

  if (plan.billingFrequency === SubscriptionPlanFrequency.Monthly) {
    return {
      taxExcluded: taxExcluded * 12,
      taxIncluded: taxIncluded * 12,
    };
  }

  return {
    taxExcluded,
    taxIncluded,
  };
};

export const getYearlySavings = <T extends GenericPricingPlan>(
  monthlyPlan: T,
  yearlyPlan: T,
) => {
  if (!monthlyPlan || !yearlyPlan) {
    return {
      percentageSaved: 0,
      taxExcluded: 0,
      taxIncluded: 0,
    };
  }

  const totalMonthlyPlan = getYearlyPricing(monthlyPlan);
  const totalYearlyPlan = yearlyPlan.pricing;

  return {
    percentageSaved:
      totalMonthlyPlan.taxExcluded > 0
        ? 1 - totalYearlyPlan.taxExcluded / totalMonthlyPlan.taxExcluded
        : 0,
    taxExcluded: totalMonthlyPlan.taxExcluded - totalYearlyPlan.taxExcluded,
    taxIncluded: totalMonthlyPlan.taxIncluded - totalYearlyPlan.taxIncluded,
  };
};
