import { type ComponentProps } from 'react';
import { Box } from '@chakra-ui/react';
import {
  getTokens,
  Tag,
  Typography,
  View,
  XStack,
  YStack,
} from '@shinetools/sunshine-universal';
import { hex } from 'wcag-contrast';

import { type FCWithChildren } from 'common/types';

type PlanCardProps = {
  title: string;
  color: NonNullable<ComponentProps<typeof View>['backgroundColor']>;
  description?: string;
  highlight?: string;
  banner?: string;
  variant?: 'small' | 'large';
};

export const PlanCard: FCWithChildren<PlanCardProps> = ({
  banner,
  children,
  color,
  description,
  highlight,
  title,
  variant = 'large',
}) => {
  const bannerHeight = 35;
  const cardBorderRadius = 16;
  // Force casting to remove TS error as we don’t care if color is not a valid property
  const backgroundColor =
    getTokens().color[color as keyof ReturnType<typeof getTokens>['color']]
      ?.val ?? color;
  const contrastRatio = hex(backgroundColor, '#fff');
  const padding =
    getTokens().space[variant === 'small' ? '$space.20' : '$space.32'].val;

  return (
    <YStack
      $large={{
        flexBasis: 0,
      }}
      borderColor={highlight ? '$grey.800' : '$grey.300'}
      borderRadius={cardBorderRadius}
      borderStyle="solid"
      borderWidth={highlight ? 1.5 : 1}
      flex={1}
      gap={variant === 'small' ? '$space.4' : '$space.32'}
      key={title}
      padding={padding}
      position="relative"
    >
      {highlight ? (
        <View
          backgroundColor={color}
          borderRadius={6}
          paddingHorizontal="$space.12"
          paddingVertical="$space.8"
          position="absolute"
          top={-18}
        >
          <Typography.Text
            size="small"
            variant={contrastRatio < 3 ? undefined : 'white'}
          >
            {highlight}
          </Typography.Text>
        </View>
      ) : null}
      <YStack flex={1} gap="$space.4">
        <XStack alignItems="center" gap="$space.16">
          <View
            backgroundColor={color}
            borderRadius={4}
            height={14}
            width={14}
          />
          <Typography.HeaderNumber size="large">
            {title}
          </Typography.HeaderNumber>
        </XStack>
        {description ? <Typography.Text>{description}</Typography.Text> : null}
      </YStack>
      {children}
      {banner ? (
        <>
          <Box marginTop={`calc(${bannerHeight}px - ${padding}px)`} />
          {/* We cannot apply overflow hidden to the card because it would cut
          the highlight tag going beyond it. So we re-create its shape */}
          <View
            borderRadius={cardBorderRadius}
            bottom={0}
            left={0}
            overflow="hidden"
            pointerEvents="none"
            position="absolute"
            right={0}
            top={0}
          >
            <Tag
              $platform-web={{
                alignSelf: 'center',
                borderRadius: 0,
                bottom: 0,
                height: bannerHeight,
                justifyContent: 'center',
                pointerEvents: 'initial',
                position: 'absolute',
                width: '100%',
              }}
              colorScheme="green"
            >
              {banner}
            </Tag>
          </View>
        </>
      ) : null}
    </YStack>
  );
};
