import { type FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { XStack, YStack } from '@shinetools/sunshine-universal';

import SunshineCard from 'components/_core/SunshineCard';
import SkeletonCircle from 'components/SkeletonCircle';
import SkeletonText from 'components/SkeletonText';

const PlanFrequencySwitchSkeleton: FC = () => {
  return (
    <YStack gap="$space.16">
      <SkeletonText skeletonHeight="space-18" width="240px" />

      <XStack gap="$space.16">
        {[1, 0].map((plan) => (
          <SunshineCard>
            <Flex justifyContent="space-between">
              <YStack gap="$space.16">
                <SkeletonText width="120px" />
                <YStack gap="$space.4">
                  <SkeletonText width="160px" />
                  {plan ? (
                    <SkeletonText skeletonHeight="space-14" width="100px" />
                  ) : null}
                </YStack>
              </YStack>
              <SkeletonCircle marginLeft="space-32" />
            </Flex>
          </SunshineCard>
        ))}
      </XStack>
      <SkeletonText skeletonHeight="space-14" width="300px" />
    </YStack>
  );
};

export default PlanFrequencySwitchSkeleton;
