import LOCALE from 'common/locale';

import amountWithMinorCurrency from './amountWithMinorCurrency';

const integerFormatter = new Intl.NumberFormat(LOCALE, {
  currency: 'EUR',
  minimumFractionDigits: 0,
  style: 'currency',
});

const decimalFormatter = new Intl.NumberFormat(LOCALE, {
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'currency',
});

/**
 * Format a price in minor currency (e.g. 100 = 1€)
 * Will show `cents` only if the price in euros is not an integer.
 */
const formatPrice = (minorCurrencyAmount: number) => {
  const isInteger = minorCurrencyAmount % 100 === 0;

  const formatter = isInteger ? integerFormatter : decimalFormatter;

  return formatter.format(amountWithMinorCurrency(minorCurrencyAmount));
};

export default formatPrice;
