import { type FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Flex, type FlexProps, Image } from '@chakra-ui/react';
import {
  Icon,
  Squircle,
  Typography,
  View,
} from '@shinetools/sunshine-universal';

import { SubscriptionPlanFrequency } from '__generated__/GQL';
import wallet from 'assets/brand/wallet.png';
import useFeatureFlagsSwitcher from 'common/hooks/useFeatureFlagsSwitcher';
import { getPlanCardColor } from 'common/plans/card';
import Button from 'components/_core/Button';
import Skeleton from 'components/Skeleton';
import SkeletonText from 'components/SkeletonText';
import { formatDuration } from 'helpers/date';

import { PLANS_CARD_IMAGES } from '../../../../lib/cardImages';
import { getTrialPeriod } from '../../../../lib/getTrialPeriod';
import * as routes from '../../../../routes';
import type * as GQL from '../../graphql/overview.gql';
import { Body } from './components/Body';
import { Container } from './components/Container';
import { Footer } from './components/Footer';
import { FooterItem } from './components/FooterItem';
import { ModifyPlanButton } from './components/ModifyPlanButton';

export type OverviewHeaderProps = FlexProps & {
  data?: GQL.SubscriptionManagementOverviewQuery;
};

export const OverviewHeader: FC<OverviewHeaderProps> = (props) => {
  const { data, ...rest } = props;

  const { featureFlags } = useFeatureFlagsSwitcher();

  const currentPlan = data?.viewer.company.currentPlan;
  const subscriptionPlan = data?.viewer.company.subscriptionPlan;

  const hasScheduledDowngrade = subscriptionPlan?.scheduledDowngrade;
  const isLoaded = Boolean(data);

  const trialPeriod = getTrialPeriod(subscriptionPlan);

  return (
    <>
      <Container {...rest}>
        <Body>
          <Flex direction="column" gap="space-12">
            <Flex align="center" gap="space-12">
              {currentPlan ? (
                <>
                  {featureFlags.enable2025PricingSubscriptionManagement ? (
                    <>
                      <View
                        backgroundColor={getPlanCardColor(
                          currentPlan.brandColor,
                        )}
                        borderRadius={4}
                        height={14}
                        width={14}
                      />

                      <Typography.HeaderNumber size="large">
                        {currentPlan.brandName}
                      </Typography.HeaderNumber>
                    </>
                  ) : (
                    <>
                      <Typography.Header size="large">
                        <FormattedMessage
                          id="subscription.overview.header.plan"
                          values={{
                            plan: currentPlan.brandName,
                          }}
                        />
                      </Typography.Header>

                      <Image
                        src={PLANS_CARD_IMAGES[currentPlan.id]}
                        width="space-40"
                      />
                    </>
                  )}
                </>
              ) : (
                <SkeletonText minWidth="space-120" skeletonHeight="36" />
              )}
            </Flex>

            <Flex direction="column" gap="space-4">
              {trialPeriod.isOngoing ? (
                <Flex align="center" gap="space-6">
                  <Icon color="$grey.800" icon="sparkles" size="small" />

                  <Typography.Text bold>
                    <FormattedMessage
                      id="subscription.overview.header.end_of_trial_period"
                      values={{
                        duration: formatDuration(trialPeriod.duration, {
                          format: ['years', 'months', 'days'],
                        }),
                      }}
                    />
                  </Typography.Text>
                </Flex>
              ) : null}

              {subscriptionPlan?.nextBillingAt ? (
                <Typography.Text variant="secondary">
                  <FormattedMessage
                    id="subscription.overview.header.next_billing_date_no_amount"
                    values={{
                      date: (
                        <FormattedDate
                          day="numeric"
                          month="short"
                          value={subscriptionPlan.nextBillingAt}
                          year="numeric"
                        />
                      ),
                    }}
                  />
                </Typography.Text>
              ) : null}
            </Flex>
          </Flex>

          {isLoaded ? (
            <Button as={Link} to={routes.plans}>
              <FormattedMessage id="subscription.overview.header.compare_offers" />
            </Button>
          ) : (
            <Skeleton height="space-40" width="space-128" />
          )}
        </Body>
        <Footer>
          <FooterItem
            borderBottomLeftRadius="radius-8"
            borderRight="1px solid"
            borderRightColor="grey.300"
          >
            <Squircle backgroundColor="$grey.200" icon="calendar" />

            <Flex justifyContent="space-between" width="100%">
              <Flex direction="column">
                <Typography.Text bold>
                  <FormattedMessage id="subscription.overview.header.billing.label" />
                </Typography.Text>

                {currentPlan ? (
                  <Typography.Text size="small" variant="secondary">
                    <FormattedMessage
                      id={`subscription.overview.header.billing.frequency.${currentPlan.billingFrequency === SubscriptionPlanFrequency.Monthly ? 'monthly' : 'yearly'}`}
                    />
                  </Typography.Text>
                ) : (
                  <SkeletonText skeletonHeight="20" />
                )}
              </Flex>
              {hasScheduledDowngrade ? null : (
                <ModifyPlanButton planId={currentPlan?.id} />
              )}
            </Flex>
          </FooterItem>

          <FooterItem borderBottomRightRadius="radius-8">
            <Image
              backgroundColor="grey.200"
              borderRadius="radius-8"
              boxSize="space-32"
              src={wallet}
            />

            <Flex direction="column">
              <Typography.Text bold>
                <FormattedMessage id="subscription.overview.header.payment_method.label" />
              </Typography.Text>

              {currentPlan ? (
                <Typography.Text size="small" variant="secondary">
                  <FormattedMessage id="subscription.overview.header.payment_method.value" />
                </Typography.Text>
              ) : (
                <SkeletonText skeletonHeight="20" />
              )}
            </Flex>
          </FooterItem>
        </Footer>
      </Container>
    </>
  );
};
